// @ts-ignore
import isEqual from 'lodash/isEqual';
// @ts-ignore
import moment from 'moment-timezone';
import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { concat, EMPTY, from, interval } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { MobileApiSession } from '../../mobile-api-types';
import { core_t } from '../CoreLocale';
import { updateActingDomain } from '../domains/DomainsActions';
import { updateActingFacility } from '../facilities/FacilitiesActions';
import { logoutRedirect, setLogoutInfo } from '../login/LoginActions';
import { showDirtyFormOrActiveOperationModal } from '../modal/ModalActions';
import { shouldShowDirtyFormOrActiveOperationModal } from '../modal/ModalEpics';
import { clearPermissions, stopPermissionsWatchers } from '../permissions/PermissionsActions';
import { hashHistory, RootState } from '../RootReducer';
import { removePagerDataFromLocalStorage } from '../search-pager/SearchPagerActions';
import store from '../store';
import { BackgroundRef, cancelRequests, req } from '../utils/api';
import { getLocalStorage } from '../utils/common';
import { markReduxFormsPristine } from '../utils/form-manager';
import log from '../utils/log';
import { getProviderIdFromRoute } from '../utils/session';
import {
  askUserToLogOut,
  bumpSessionExpiration,
  logout,
  refreshSession,
  sendUserToIdp,
  startSessionWatchers,
  stopSessionWatchers,
  updateSession,
} from './SessionActions';
import { SessionAction } from './SessionReducer';
import { LogoutReason } from '../login/LoginReducer';

export const LOCAL_STORAGE_SESSION_KEY = 'admin-webapp.session';

const localStorage = getLocalStorage()!;

const piiKeysList = ['email', 'name'] as const;

type MobileApiSessionWithNoPii = Omit<MobileApiSession, 'name' | 'email'>;

// Retrieve session from localstorage.
function getSessionFromLocalStorage(): MobileApiSessionWithNoPii | null {
  const sessionString = localStorage.getItem(LOCAL_STORAGE_SESSION_KEY);
  return sessionString && JSON.parse(sessionString);
}

// Extends sessionExpiration by the idleTimeout or 30 minutes.
export const bumpSessionExpirationEpic: Epic<SessionAction, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(bumpSessionExpiration)),
    debounceTime(2500),
    withLatestFrom(state$.pipe(map(s => s.session))),
    switchMap(([, session]) => {
      if (session) {
        let idleTimeout = session.idleTimeout;
        if (!idleTimeout || idleTimeout < 0) {
          idleTimeout = 30;
        }
        return [
          updateSession({
            ...session,
            expires: moment().add(idleTimeout, 'minutes').toDate(),
          }),
        ];
      } else {
        return [];
      }
    })
  );

// Handles localStorage of session.
export const updateSessionEpic: Epic<SessionAction, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(updateSession)),
    switchMap(action => {
      const nextSession = action.payload.session;
      if (nextSession) {
        const { name, email, ...restSession } = nextSession;
        localStorage.setItem(LOCAL_STORAGE_SESSION_KEY, JSON.stringify(restSession));
      } else {
        localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY);
      }
      return [];
    })
  );

// Fetches a fresh session from the server, optionally dispatching actions once successfully completed.
export const refreshSessionEpic: Epic<SessionAction, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(refreshSession)),
    switchMap(action =>
      req<MobileApiSession>(
        ['session', [], 'GET', { skipPermissionCheck: action.payload.skipPermissionCheck }],
        store,
        BackgroundRef
      ).pipe(
        switchMap(response =>
          action.payload.successActions
            ? [updateSession(response.data), ...action.payload.successActions(response)]
            : [updateSession(response.data)]
        ),
        catchError((error: any) => {
          log.error('Failed to refresh session', error);
          return EMPTY;
        })
      )
    )
  );

// Periodically updates the store to match localStorage.
export const localStorageEpic: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(startSessionWatchers)),
    switchMap(() =>
      interval(5000).pipe(
        withLatestFrom(state$.pipe(map(s => s.session))),
        switchMap(([, currentSession]): Action[] => {
          const sessionFromLocalStorage = copyPii(currentSession, getSessionFromLocalStorage());
          if (currentSession && !sessionFromLocalStorage) {
            // user logged out on a different tab
            return [logout()];
          } else if (
            sessionFromLocalStorage &&
            currentSession &&
            !isEqual(sessionFromLocalStorage, currentSession)
          ) {
            if (currentSession.userId !== sessionFromLocalStorage.userId) {
              log.info('User logged in as different user on a different tab.');
            }

            return [updateSession(sessionFromLocalStorage)];
          } else {
            return [];
          }
        }),
        takeUntil(action$.pipe(ofType(stopSessionWatchers)))
      )
    )
  );

/**
 * Return a copy of newSesison with the PII from oldSession
 */
function copyPii(
  oldSession: MobileApiSession | null,
  newSession: MobileApiSessionWithNoPii | null
): MobileApiSession | null {
  if (!oldSession || !newSession) {
    return null;
  }

  return {
    ...newSession,
    ...piiKeysList.reduce((acc, piiKey) => {
      return {
        ...acc,
        [piiKey]: oldSession[piiKey],
      };
    }, {}),
  } as MobileApiSession;
}

// Periodically check if session exists, if not then logout.
export const sessionExpirationEpic: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(startSessionWatchers)),
    switchMap(() =>
      interval(10000).pipe(
        withLatestFrom(state$.pipe(map(s => s.session))),
        switchMap(([, currentSession]) => {
          if (
            currentSession &&
            currentSession.expires &&
            moment(currentSession.expires).add(-10, 'seconds').isBefore(moment())
          ) {
            return [logout(LogoutReason.SessionExpired)];
          } else {
            return [];
          }
        }),
        takeUntil(action$.pipe(ofType(stopSessionWatchers)))
      )
    )
  );

const getLogoutMessageFromReason = (reason: LogoutReason) => {
  switch (reason) {
    case LogoutReason.UserInitiated:
      return core_t(['login', 'logoutSuccess']);
    case LogoutReason.SessionExpired:
      return core_t(['homePage', 'message', 'noLongerSignedIn']);
    default:
      return '';
  }
};

// Logout user.
export const logoutEpic: Epic<SessionAction, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(logout)),
    withLatestFrom(state$.pipe(map(s => s.session))),
    switchMap(([action]) => {
      const message = getLogoutMessageFromReason(action.payload.reason);
      const loginDestination =
        action.payload.reason !== LogoutReason.UserInitiated && hashHistory.location.pathname;
      // Mark all forms as pristine and cancel all requests so we don't get a confirmation modal
      markReduxFormsPristine([]);
      cancelRequests(null);

      return concat(
        from([
          setLogoutInfo(action.payload.reason, message, loginDestination || undefined),
          stopPermissionsWatchers(),
          stopSessionWatchers(),
          updateSession(null),
          updateActingDomain(null),
          updateActingFacility(null),
          clearPermissions(),
          removePagerDataFromLocalStorage(),
          logoutRedirect(loginDestination || '/', getProviderIdFromRoute()),
        ])
      );
    })
  );

// Asks the user if he/she wises to log out only if there is a dirty form
export const askUserToLogOutEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(askUserToLogOut)),
    switchMap(() => [
      shouldShowDirtyFormOrActiveOperationModal([])
        ? showDirtyFormOrActiveOperationModal(
            core_t(['logoutWarningConfirmation', 'title']),
            core_t(['logoutWarningConfirmation', 'message']),
            [],
            () => [logout(LogoutReason.UserInitiated)]
          )
        : logout(LogoutReason.UserInitiated),
    ])
  );

export const sendUserToIdpEpic: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(sendUserToIdp)),
    withLatestFrom(state$.pipe(map(state => state.session))),
    switchMap(([, currentSession]) => {
      // @ts-ignore
      const { location, idpPath, oauth2CallbackUrl } = window;
      const newLocation = `${idpPath}/authorize?client_id=${encodeURIComponent(
        currentSession?.providerId ?? ''
      )}&response_type=code&redirect_uri=${encodeURIComponent(
        oauth2CallbackUrl
      )}&informacast_path=${encodeURIComponent(location.href)}`;

      window.location.assign(newLocation);
      return [];
    })
  );

export default combineEpics(
  bumpSessionExpirationEpic,
  updateSessionEpic,
  refreshSessionEpic,
  localStorageEpic,
  sessionExpirationEpic,
  logoutEpic,
  askUserToLogOutEpic,
  sendUserToIdpEpic
);
