import { COLORS } from 'singlewire-components';
import styled from 'styled-components';
import Routes from '../../core/route/Routes';
import { Link } from '../shared/components/Link';
import { FooterProps } from './FooterContainer';
import { layout_t } from './LayoutLocale';

const StyledFooter = styled.footer`
  margin-top: 1rem;
  color: ${COLORS.GRAY_60} !important;
`;

const StyledLink = styled(Link)`
  border-radius: 0.25rem;
  color: ${COLORS.GRAY_60} !important;
  &:hover {
    color: ${COLORS.GRAY_80} !important;
  }

  &:focus-visible {
    color: ${COLORS.GRAY_80} !important;
    outline: 2px solid white;
    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 2px,
      rgb(0, 90, 138) 0px 0px 0px 4px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
`;

const StyledA = styled.a`
  border-radius: 0.25rem;
  color: ${COLORS.GRAY_60} !important;
  &:hover {
    color: ${COLORS.GRAY_80} !important;
    text-decoration: underline;
  }

  &:focus-visible {
    color: ${COLORS.GRAY_80} !important;
    text-decoration: underline;
    outline: 2px solid white;
    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 2px,
      rgb(0, 90, 138) 0px 0px 0px 4px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
`;

export const Footer = ({ isFusionMode }: FooterProps) => {
  return (
    <StyledFooter id="footer">
      <div className="container">
        <span>{layout_t(['footer', 'copyright'])}</span>
        <ul className="f-menu">
          <li>
            <StyledLink to={Routes.Home}>{layout_t(['footer', 'home'])}</StyledLink>
          </li>
          <li>
            <StyledA
              href={`https://www.singlewire.com/${
                isFusionMode ? 'informacast-fusion' : 'informacast-mobile.html'
              }`}
              rel="noopener noreferrer"
              target="_blank">
              {layout_t(['footer', 'about'])}
            </StyledA>
          </li>
          <li>
            <StyledA
              href="https://www.singlewire.com/support-tools"
              target="_blank"
              rel="noopener noreferrer">
              {layout_t(['footer', 'support'])}
            </StyledA>
          </li>
        </ul>
      </div>
    </StyledFooter>
  );
};
