import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ButtonProps, ModalProps } from 'singlewire-components';
import { RootState } from '../../core/RootReducer';
import { confirmModal, hideModal } from '../../core/modal/ModalActions';
import { CustomRegistryModal } from './CustomRegistryModal';

interface ExternalProps {
  id: string;
  title: string;
  subtitle?: string;
  modalProps?: Partial<ModalProps>;
  render?: (props: any) => ReactNode;
  children?: ReactNode;
  confirmCb?: () => Action[];
  primaryLabel?: string;
  primaryButtonProps?: Partial<ButtonProps>;
  hidePrimaryButton?: boolean;
  secondaryButtonProps?: Partial<ButtonProps>;
  secondaryLabel?: string;
  hideSecondaryButton?: boolean;
  onClose?: () => void;
  isLoading?: boolean;
  // Makes modal only dismissable via hideModal()
  isChainable?: boolean;
}

export type CustomRegistryModalProps = ExternalProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const mapStateToProps = (state: RootState, ownProps: ExternalProps) => ({
  isShown: !!state.modal.modal && (state.modal.modal.id === ownProps.id || !!ownProps.isChainable),
  componentProps: state.modal.modal && state.modal.modal.componentProps,
});

export const mapDispatchToProps = { hideModal, confirmModal };

export const CustomRegistryModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomRegistryModal);
