// Functions for transforming data into a useful string for display to a user.
import moment from 'moment';
import { common_t } from '../../CommonLocale';
import {
  MobileApiCollaborationGroup,
  MobileApiDevice,
  MobileApiDistributionList,
  MobileApiIncidentPlan,
  MobileApiUser,
} from '../../mobile-api-types';
import { core_t } from '../CoreLocale';

/**
 * Given any resource, return either its description, name, id, or the entity itself. Most Mobile
 * resources and ICOP resources have a name prop so it will use that in most cases.
 * @param resource the resource
 * @returns {string} the string representation of the resource
 */
export function formatDefault(resource: any): string {
  return resource?.description || resource?.name || resource?.id || resource;
}

/**
 * Returns a formatted version of the incident plan
 * @param {MobileApiIncidentPlan} incidentPlan the incident plan
 * @returns {string} the formatted string
 */
export function formatIncidentPlan(incidentPlan: MobileApiIncidentPlan): string {
  return incidentPlan.name;
}

/**
 * SW-18053 - Multicast streams' descriptions are not user friendly. This function
 * checks the InformaCast device type and extracts the name if it's `Multicast Stream`,
 * the name is then prefixed with the localized "Multicast Stream" qualifier.
 * Other device types are represented by their descriptions. The `useFalback` flag
 * indicates whether or not the Icop Id should be used instead of an empty device description.
 * @param device The device whose name or description is being formatted
 * @param useFallback Whether or not to use Icop Id if the description is empty
 * @returns The string representation of the device using either its name or description.
 */
function formatDeviceName(device: MobileApiDevice, useFallback = false): string {
  return device.attributes?.InformaCastDeviceType === 'Multicast Stream'
    ? `${common_t(['resource', 'multicastStream'])}: ${device.attributes.Name}`
    : useFallback
      ? device.description || device.icopId
      : device.description;
}

/**
 * Given a Mobile Device, format it to prepend whether the device is licensed or defunct
 * @param {MobileApiDevice} device the device
 * @param {boolean} ignoreLicense whether we should omit the license check
 * @param {boolean} deviceDeleted whether device has been deleted (but still have icopId ref)
 * @returns {string} the formatted string representation of the device
 */

export function formatDevice(
  device: MobileApiDevice,
  ignoreLicense = false,
  deviceDeleted = false
): string {
  if (device.defunct || deviceDeleted) {
    return `${core_t(['label', 'defunct'])}`;
  } else if (!(device.isLicensed || ignoreLicense)) {
    return `${core_t(['label', 'unlicensed'])}: ${formatDeviceName(device)}`;
  } else {
    return formatDeviceName(device, true);
  }
}

/**
 * Given a user, returns a formatted version of the user's name and whether that user is locked
 * @param {MobileApiUser} user the user
 * @returns {string} the formatted string
 */
export function formatUser(user: MobileApiUser): string {
  const lockedString = !!user.isLocked ? ` (${core_t(['label', 'locked'])})` : '';
  const licensedString = !user.isLicensed ? ` (${core_t(['label', 'notLicensed'])})` : '';
  return user.type === 'regular'
    ? `${user.name} <${user.email}>${lockedString}${licensedString}`
    : `${user.name}${lockedString}${licensedString}`;
}

/**
 * Returns a formatted version of the distribution list name and the status of it's campaign
 * @param {MobileApiDistributionList} distributionList teh distribution list
 * @returns {string} the formatted string
 */
export function formatDistributionList(distributionList: MobileApiDistributionList): string {
  let postText = '';
  const campaign = distributionList.campaign;
  if (campaign) {
    if (campaign.enrollmentEndDate && moment(campaign.enrollmentEndDate).isBefore(moment())) {
      postText = ` ${core_t(['messageTemplateDetails', 'label', 'inactiveCampaign'])}`;
    } else {
      postText = ` ${core_t(['messageTemplateDetails', 'label', 'campaign'])}`;
    }
  }
  return `${distributionList.name}${postText}`;
}

/**
 * Returns a formatted version of the collaboration group and its type
 * @param {MobileApiCollaborationGroup} collaborationGroup the collaboration group
 * @returns {string} the formatted string
 */

export function formatCollaborationGroup(collaborationGroup: MobileApiCollaborationGroup) {
  let postText = '';
  switch (collaborationGroup.type) {
    case 'cisco-spark':
      postText = ` ${core_t(['messageTemplateDetails', 'label', 'ciscoWebexTeams'])}`;
      break;
    case 'conference-call':
      postText = ` ${core_t(['messageTemplateDetails', 'label', 'conferenceCall'])}`;
      break;
    case 'msteams-channel':
      postText = ` ${core_t(['messageTemplateDetails', 'label', 'msteamsChannel'])}`;
      break;
    case 'outbound-cap':
      postText = ` ${core_t(['messageTemplateDetails', 'label', 'outboundCap'])}`;
      break;
    case 'outbound-rss':
      postText = ` ${core_t(['messageTemplateDetails', 'label', 'outboundRss'])}`;
      break;
    case 'twitter':
      postText = ` ${core_t(['messageTemplateDetails', 'label', 'twitter'])}`;
      break;
  }
  return `${collaborationGroup.name}${postText}`;
}

export function formatDateString(
  ts: number | string | Date,
  style: 'long' | 'short' | 'medium' | 'full'
): string {
  return Intl.DateTimeFormat([], { dateStyle: style }).format(new Date(ts));
}

/**
 * Creates formatted date and time string
 * @param ts time string
 * @param dateStyle style to use for date format
 * @param timeStyle optional style to use for time format. If not provided, will default to same as date format
 * @returns formatted date and time
 */
export function formatDateTimeString(
  ts: number | string | Date,
  dateStyle: 'long' | 'short' | 'medium' | 'full',
  timeStyle?: 'long' | 'short' | 'medium' | 'full'
): string {
  const timeStyleOption = timeStyle ? timeStyle : dateStyle;
  return Intl.DateTimeFormat([], { dateStyle: dateStyle, timeStyle: timeStyleOption }).format(
    new Date(ts)
  );
}

/**
 * Returns a boolean formatted as a locale specific "yes" for true
 * or "no" for false.
 * @returns {string} localized yes / no
 */
export function formatBooleanYesNo(yesNo: boolean): string {
  return yesNo ? common_t(['label', 'yes']) : common_t(['label', 'no']);
}

export function formatFileSize(bytes: number, precision = 1): string {
  if (!isFinite(bytes)) {
    return '-';
  }
  const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
  const number = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, Math.floor(number))).toFixed(precision)} ${units[number]}`;
}
