import { AxiosError } from 'axios';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { Typography } from 'singlewire-components';
import {
  LAYOUT_CONFIRM_MODAL_ID,
  LAYOUT_INFO_MODAL_ID,
  LAYOUT_REGISTRY_MODAL_ID,
} from '../../constants';
import { ModalState } from '../../core/modal/ModalActions';
import { MobileApiValidationIcopErrorResponse } from '../../mobile-api-types';
import { ConfirmationRegistryModalContainer } from '../shared/ConfirmationModalContainer';
import { CustomRegistryModalContainer } from '../shared/CustomRegistryModalContainer';
import { InformationRegistryModalContainer } from '../shared/InformationRegistryModalContainer';
import { Alert } from '../shared/components/Alert';
import { layout_t } from './LayoutLocale';
import { ModalsProps } from './ModalsContainer';

export const getErrors = (modal: ModalState): string[] => {
  const error =
    modal.response && (modal.response as AxiosError<MobileApiValidationIcopErrorResponse>).response;
  const overridenErrors = modal.errorOverride && modal.errorOverride(error);
  if (overridenErrors && !isEmpty(overridenErrors)) {
    return overridenErrors;
  }

  if (
    error &&
    error.status === 400 &&
    error.data &&
    isArray(error.data.reason) &&
    !isEmpty(error.data.reason) &&
    error.data.reason[0].extras &&
    error.data.reason[0].extras.messageCode === 'ERROR_DEPENDENCIES_EXIST'
  ) {
    const dependencyErrors = Object.values(error.data.reason[0].extras.dependencies || {}).reduce(
      (acc: any[], val) => acc.concat(val),
      []
    );
    return [
      ...error.data.reason.map((r: any) => r.message as string),
      ...dependencyErrors.map(dep => dep.dependencyText),
    ];
  }

  return error &&
    error.status === 400 &&
    error.data &&
    isArray(error.data.reason) &&
    !isEmpty(error.data.reason)
    ? error.data.reason.map((r: any) => r.message as string)
    : [modal.failureMessage || layout_t(['modals', 'errorMessage'])];
};

export const getModalErrorMessage = (modal: ModalState) => (
  <div>
    {getErrors(modal).map((error, idx) => (
      <Alert color="error" slim key={idx}>
        {error}
      </Alert>
    ))}
  </div>
);

export const Modals = ({ globalModal }: ModalsProps) =>
  globalModal && (
    <>
      <ConfirmationRegistryModalContainer
        id={LAYOUT_CONFIRM_MODAL_ID}
        title={globalModal.title || layout_t(['modals', 'errorMessage'])}
        textConfirm={globalModal.textConfirm}
        textConfirmLabel={globalModal.textConfirmLabel}
        isDestructive={globalModal.isDestructive}
        confirmTitle={globalModal.confirmTitle}>
        {globalModal.messageHtml ? (
          <div dangerouslySetInnerHTML={{ __html: globalModal.messageHtml }} />
        ) : (
          globalModal.message
        )}
      </ConfirmationRegistryModalContainer>

      <InformationRegistryModalContainer
        id={LAYOUT_INFO_MODAL_ID}
        title={globalModal.title || layout_t(['modals', 'errorTitle'])}>
        {globalModal.messageHtml ? (
          <div dangerouslySetInnerHTML={{ __html: globalModal.messageHtml }} />
        ) : (
          globalModal.message || getModalErrorMessage(globalModal)
        )}
      </InformationRegistryModalContainer>

      <CustomRegistryModalContainer
        id={LAYOUT_REGISTRY_MODAL_ID}
        title={globalModal.title || layout_t(['modals', 'errorMessage'])}>
        {!globalModal.messageHtml ? (
          <Typography variant="subtitle">{globalModal.rawMessage}</Typography>
        ) : undefined}
        {globalModal.messageHtml && (
          <div dangerouslySetInnerHTML={{ __html: globalModal.messageHtml }} />
        )}
      </CustomRegistryModalContainer>
    </>
  );
