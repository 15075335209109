import { QueryClient } from '@tanstack/query-core';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from './RootEpic';
import rootReducer, { hashHistory, rootReducers, RootState } from './RootReducer';
import { Logger } from './utils/log';

const logger = new Logger();

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Tracing apparently comes with a performance cost, so don't do this for production.
        trace: process.env.NODE_ENV !== 'production',
      })
    : compose;

// This is for wiring our epics up to the store.
const epicMiddleware = createEpicMiddleware();

export const store: Store<RootState> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(routerMiddleware(hashHistory), epicMiddleware))
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Mutable collection of reducers so that the whole application has a reference to all of them,
// updated as new ones are added.
let lazyReducers = {};
export const registerLazyReducer = (name: string, reducer: any) => {
  if (!lazyReducers[name]) {
    lazyReducers = { ...lazyReducers, [name]: reducer };
    store.replaceReducer(combineReducers({ ...rootReducers, ...lazyReducers }));
  } else {
    logger.warn(`You are registering multiple reducers with the same name: ${name}`);
  }
};

epicMiddleware.run(rootEpic);

export default store;

// TODO - add tests  (registerLazyReducer)
