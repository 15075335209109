import { registerResource } from '../../../../core/utils/localization';

export const SitesLocale = {
  en: {
    plural: 'Sites',
    subtitle: {
      list: "List sites, which are collections of site roles and their associated recipients, e.g. distribution lists, outbound systems, users, etc., that may receive notifications based on a scenario's configuration. ",
      listPane:
        'Sites are spaces of ground within your organization tied to a collection of site roles. These site roles may receive notifications if their site is chosen when initiating a scenario.',
      create:
        'Create a new site, which could be an entire campus with multiple buildings, a single building and its parking lot, etc.',
      details: 'View/edit a site, its site roles, and their associated recipients.',
      siteFloorDetails: 'View/edit a floor, its details, and rooms (if applicable).',
      siteBuildingDetails: 'View/edit a building, its details, and floors/rooms (if applicable).',
      siteFloorCreate:
        'Add a floor to a site, which could be an actual floor in your site or simply a subset of your site, such as an upper versus lower reception area.',
      siteBuilding:
        'Add a building to a site, which could be an actual building or simply a subset of your site, such as an equipment shed or a parking lot.',
    },
    form: {
      floorPlanImage: 'Floor Plan Image',
      noSiteRoles: 'No Site Roles',
      recipients: 'Associated Site Roles',
      recipientsHelpText:
        'View/edit the site roles associated with your site. When a site is chosen while initiating a standard or integration scenario, the recipients assigned to its site roles will receive the notifications.',
      recipientsAddText:
        'Select an existing site role (or create a new one) and assign recipients to it.',
      floors: 'Floor Entries',
      floorDetails: 'Floor Details: {{name}}',
      uploadFloorPlan: 'Add further detail to your floor by uploading a floor plan.',
      uploadFloorPlanFileRestrictions:
        'Image files must be {{validTypes}}, and they cannot exceed 20 MB in size.',
      reordering: 'Reorder Floors',
      reorderFloorsSubtitle:
        'Hover over a row in the table to reveal the controls that allow you to change the display order of floors.',
      reorderingHelpText:
        'Change the order in which your floors appear in InformaCast, e.g. the Penthouse is always first followed by the highest numbered floor. By default, InformaCast lists your floors in the order in which they were added.',
      floorsHelpText: 'View/edit the floors associated with this site.',
      zonesHelpText: 'View/edit the rooms associated with this floor.',
      basicInfoHelpText: 'Name your Site.',
      floorBasicInfoHelpText:
        'Name your floor and select its type, e.g. floor, suite, penthouse, etc.',
      addFloor: 'Add Floor to {{buildingName}}',
      addFloorNoBuildingName: 'Add Floor',
      addBuilding: 'Add Building to {{siteName}}',
      siteIntegrations: 'Site Integrations',
      siteIntegrationsDetails: 'Manage the integrations linked to this site.',
      buildingIntegrations: 'Building Integrations',
      buildingIntegrationsDetails: 'Manage the integrations linked to this building.',
      floorIntegrations: 'Floor Integrations',
      floorIntegrationsDetails: 'Manage the integrations linked to this floor.',
      zoneIntegrations: 'Room Integrations',
      zoneIntegrationsDetails: 'Manage the integrations linked to this room.',
      integrationName: 'Integration Name',
      fccId: 'Enter the number for your local PSAP.',
      respondingPsap: 'Enter the name for your local PSAP.',
      preferredPsapNumber: 'Enter the contact number for you local PSAP.',
      validFloorPlanImageTypes: 'GeoTIFF, JPEG, or PNG',
      uploadFailed: 'Upload Failed',
      globalSite: 'Global Site',
      global: 'Global',
      local: 'Local',
      globalSiteSubtitle:
        'The site and its resources will be visible to the InformaCast users and administrators assigned to the global level. Global-level sites can be moved to the local level.',
      localSiteSubtitle:
        'The site and its resources will only be visible to the InformaCast users and administrators assigned to this site. Local-level sites can never be moved to the global level.',
    },
    facilities: {
      localTitle: "Make '{{siteName}}' a Local Site",
      localBody:
        "Making '{{siteName}}' a Local Site will remove it from 'Global'. This will also remove all associated recipients and site roles from this site. Add more detail here.",
      failureMessage: 'Failure Message',
      successMessage: 'Success Message',
    },
    integrationScenariosConfirmModal: {
      title: 'Confirm Site Update',
      message:
        'Changing this site may also change the following integration scenarios or render them ineffective: {{launchers}}. Test your integration scenarios after making site changes.',
    },
    label: {
      localSite: 'Local Site',
      showOnlyEmergencyBuildings: 'Show Only Emergency Buildings',
      emergencySite: 'Emergency Site',
      numBuilding: '{{num}} Building',
      numBuildings: '{{num}} Buildings',
      numFloor: '{{num}} Floor',
      numFloors: '{{num}} Floors',
      siteDetailsTitle: 'Site Details',
      goToLocation: 'Go to {{location}}',
      buildingDetailsTitle: 'Building Details',
      returnToSiteRecipient: 'Return to Site Recipient {{name}}',
      createSiteRole: 'Create New Site Role',
      source: 'Source',
      zoomSource: 'Zoom',
      defaultSource: 'InformaCast',
      roleName: 'Role Name',
      selectSiteRole: 'Select from existing role names or create a new one.',
      siteRoleRecipients: 'Site Role Recipients',
      siteRoleRecipientsSubtitle: 'Select the recipients for this role.',
      editSiteRecipient: 'Edit Site Role Recipients',
      addSiteRole: 'Add Role To Site',
      currentlyAssigned: 'View/edit the recipients assigned to this site role.',
      reorderFloors: 'Reorder Floors',
      buildingName: 'Name',
      floorName: 'Name',
      unitType: 'Type',
      zones: 'Rooms',
      floors: 'Floors',
      buildings: 'Buildings',
      sites: 'Sites',
      building: 'Building',
      floor: 'Floor',
      floorPlan: 'Floor Plan',
      addressPlaceholder: '1601 Deming Way',
      addressLine2: 'Apartment, suite, etc. (Optional)',
      addressLine2Placeholder: 'Apt 21',
      city: 'City',
      state: 'State',
      postalCode: 'Postal Code',
      country: 'Country',
      phone: 'Phone Number',
      latitude: 'Latitude',
      longitude: 'Longitude',
      respondingPsap: 'PSAP Name',
      preferredPsapNumber: 'PSAP Contact Number',
      fccId: 'Public Safety Answering Point (PSAP) ID',
      setAsEmergency: 'Enable Emergency Calling',
      emergency: 'Emergency',
      unitTypes: {
        APT: 'Apartment',
        BSMT: 'Basement',
        BLDG: 'Building',
        DEPT: 'Department',
        FL: 'Floor',
        FRNT: 'Front',
        HGNR: 'Hangar',
        LBBY: 'Lobby',
        LOT: 'Lot',
        LOWR: 'Lower',
        OFC: 'Office',
        PH: 'Penthouse',
        PIER: 'Pier',
        REAR: 'Rear',
        RM: 'Room',
        SIDE: 'Side',
        SLIP: 'Slip',
        SPC: 'Space',
        STOP: 'Stop',
        STE: 'Suite',
        TRLR: 'Trailer',
        UNIT: 'Unit',
        UPPR: 'Upper',
      },
    },
    button: {
      toggleLeftPane: 'Toggle left pane',
      editFloorPlanLocation: 'Modify Floor Plan',
      modifyBoundary: 'Modify Boundary',
      rotateFloorPlan: 'Rotate Floor Plan 90 Degrees',
      replaceFloorPlan: 'Replace Floor Plan',
      toggleFloorPlanUploadModal: 'Toggle Floor Plan Upload Modal',
      acceptSuggestedZoneChange: 'Adjust Boundary',
    },
    instructions: {
      uploadFloorPlanTitle: 'Upload Floor Plan',
      uploadFloorPlanBody:
        "This floor doesn't have a floor plan configured. Please upload a floor plan to proceed.",
      hasExistingFloorPlanTitle: 'Modify Floor Plan',
      hasExistingFloorPlanBody:
        "Click the Modify Floor Plan icon to modify your floor plan's location, replace it or delete it. Click the Modify Boundary icon to change your floor plan’s defined boundaries.",
      editingFloorPlanTitle: 'Position Floor Plan',
      editingFloorPlanBody:
        "Drag the floor plan over your building and use the image handles to adjust it to mirror your building's size and position. Click ✓ to save the position.",
      editingZoneBoundaryTitle: 'Editing A Room',
      editingZoneBoundaryFirstPointBody:
        'Click a spot inside the floor boundary to set your first point.',
      editingBoundaryAfterFirstPointBody:
        'Continue clicking to set additional points. Finish a boundary by clicking an existing point.',
      editingFloorBoundaryFirstPointBody:
        'Click a spot on the map to set the first point of your boundary.',
      editingZoneBoundaryBody:
        'Use the boundary handles to drag and draw a boundary around your room. Click ✓ to save the boundary.',
      editingBoundaryTitle: 'Define Boundary',
      editingBoundaryBody:
        'Use the boundary handles to drag and draw a boundary around your floor plan. Click ✓ to save the boundary.',
    },
    corrections: {
      zoneIntersects: "Rooms' Boundaries Overlap",
      zoneIntersectsFixing: 'Automatically adjust your rooms to remove the overlapping boundaries.',
      zoneIntersectsNoFixing: 'Adjust your rooms to remove the overlapping boundaries.',
      escapesFloor: 'Room Crosses Boundary',
      escapesFloorFixing:
        "Automatically adjust your room boundary to fall within your floor plan's boundary.",
      escapesFloorNoFixing: "Adjust your room boundary to fall within your floor plan's boundary.",
      zoneAndFloor: 'Multiple Boundary Issues',
      zoneAndFloorFixing:
        "Automatically adjust your rooms to move them within your floor plan's boundary and remove any overlapping boundaries.",
      zoneAndFloorNoFixing:
        "Adjust your rooms to place them within your floor plan's boundary and remove any overlapping boundaries.",
      outsideCircle: 'Place the point closer to the floor plan image.',
      outsideParent: "Place the point inside the floor's boundary.",
      insideOtherZone: 'Place the point outside of an existing room.',
    },
    tooltip: {
      showMap: 'Hide/Show Map',
      singleSite: {
        siteName: 'Site name',
        numBuildings: 'Number of buildings in this site',
        hasEmergencyBuilding: 'This site has an emergency building.',
        numRecipients: 'Number of Recipients',
        backToSitesList: 'Back to sites list',
      },
      singleBuilding: {
        buildingName: 'Building name',
        backToBuildingsList: 'Back to buildings list',
      },
      singleFloor: {
        floorName: 'Floor name',
        backToFloorsList: 'Back to floors list',
        numZones: 'Number of rooms on this floor',
        type: 'Type',
        hasFloorPlan: 'Has floor plan uploaded',
      },
      singleZone: {
        zoneName: 'Room name',
        backToZonesList: 'Back to rooms list',
        hasLayout: 'Has Boundary',
        noLayout: 'No Boundary',
      },
    },
    request: {
      siteRoleSuccess:
        'You have successfully updated the recipients for Site Role {{siteRoleName}}.',
      siteFloorSuccess: 'You have successfully updated the floor {{siteFloorName}}.',
      siteFloorImageSuccess: 'You have successfully updated the floor plan.',
      siteBuildingSuccess: 'You have successfully updated the building {{siteBuildingName}}.',
      siteReorderSuccess:
        'You have successfully reordered the floors for site {{siteBuildingName}}.',
      siteZoneBoundarySuccess:
        'You have successfully updated the boundary for the room {{zoneName}}.',
      siteBoundaryValidationFailure: 'Use at least three unique points in your boundary.',
      siteZoneBoundaryChangeAffectsDevices: 'Boundary Change Affects Devices',
      siteZoneBoundaryBeingAdjusted: "You have chosen to adjust the {{zoneName}}'s boundary.",
      siteZoneBoundaryNowIncludesSingular:
        'Your change now includes the following device: {{deviceNames}}.',
      siteZoneBoundaryNowExcludesSingular:
        'Your change now excludes the following device: {{deviceNames}}.',
      siteZoneBoundaryNowIncludes:
        'Your change now includes the following devices: {{deviceNames}}.',
      siteZoneBoundaryNowExcludes:
        'Your change now excludes the following devices: {{deviceNames}}.',
      siteZoneDeleteMessage:
        'You have chosen to delete the {{name}} room. Deleting this room will permanently remove it from InformaCast and reassign its API devices to the {{floorName}} floor.',
    },
    site: {
      siteEntries: 'Site Entries',
      siteDetailsTitle: 'Site Details',
      siteDetails: 'View and manage your existing sites, delete them, and/or create new ones.',
      noSites: 'No sites. Click the Add Sites button to add a Site.',
      noSitesFound: 'No sites found.',
      changeSiteTitle: 'Change Global Site to Local',
      changeSiteFailure:
        'This operation failed. Please try again. If problems continue, contact your system administrator.',
      changeSiteSuccess: 'You have successfully updated the {{name}} {{resource}}.',
      changeGlobalToLocalSite:
        'You have chosen to change a global site, {{name}}, to a local site. You cannot change a local site back to a global site.',
      deleteSiteWarningText:
        'You have chosen to delete the {{siteName}} site. Deleting this site will permanently remove it and all of its buildings, floors, and rooms from InformaCast.',
      deleteSiteModalTitle: 'Action Cannot Be Completed',
      deleteSiteModalAlertTitle: 'Unable to remove Site',
      deleteSbfzModalAlertTitle: 'Unable to remove {{sbfz}}',
      deleteSiteModalBody:
        'You cannot delete the last site with an emergency building association while you still have standard or integration scenarios using emergency calling. Depending on your permissions or domain, you may not be able to see these scenarios.',
      deleteSbfzDependentDeviceBody1:
        'You cannot delete this {{sbfz}} because it is the location for at least one device.',
      deleteSbfzDependentDeviceBody2: 'Click here to view the linked devices.',
      deleteLocalSiteWarning:
        'You are about to delete a local site. All resources associated with this site will also be deleted upon this confirmation and cannot be undone.',
    },
    siteRecipient: {
      delete: {
        title: 'Delete Recipient Associations',
        confirm:
          'You have chosen to delete the recipient associations currently configured for the {{role}} site role. Deleting these recipient associations will permanently remove them from InformaCast.',
        failure:
          'The Site Recipient for Site Role {{role}} has not been removed. Please try again. If problems continue, contact your system administrator.',
        success:
          'You have successfully deleted the recipient associations for the {{role}} site role.',
      },
      noSiteRecipients: 'No recipients assigned',
      noSiteRecipientsRationale: 'Click the Edit button to assign site role recipients.',
    },
    siteRole: {
      subtitle: 'Configure Site Roles.',
      noSiteRoles: 'No site roles',
      noSiteRolesRationale: 'Click the Add Role To Site button to get started.',
    },
    siteFloor: {
      noFloorPlan: 'No Floor Plan',
      noFloorPlanLower: 'No floor plan',
      noFloorBoundary: 'No Floor Boundary',
      noSiteFloors: 'No floors. Click the Add Floor button to add a floor.',
      noFloorsFound: 'No floors found.',
      deleteSiteFloorWarningText:
        'You have chosen to delete the {{name}} floor. Deleting this floor will permanently remove it and all of its rooms from InformaCast.',
    },
    siteRoom: {
      listTitle: 'Room Entries',
      listSubtitle: 'View/edit the rooms associated with this floor.',
      createTitle: 'Add Room: {{floorName}}',
      createSubtitle:
        'Add a room to a floor, which could be an actual room or simply a subset of your floor, such as a utility closet or hallway.',
      createSubtitleBasicInfo: 'Name your room and select its type, e.g. office, side, unit, etc.',
      editSubtitle:
        'Edit a room on a floor, which can be an actual room on your floor or simply a subset of your floor, such as utility closet or hallway.',
      editSubtitleBasicInfo: 'Name your room and select its type, e.g. office, side, unit, etc.',
      noSiteRooms: 'No site rooms. Click the Create Room button to add a site room.',
    },
    siteBuilding: {
      listTitle: 'Site Buildings',
      listSubtitle: 'View/edit the buildings associated with this site.',
      details: 'Building Details',
      floors: 'Floors',
      noSiteBuildings: 'No buildings. Click the Add Building button to add a building.',
      noBuildingsFound: 'No buildings found.',
      deleteSiteBuildingWarningText:
        "You have chosen to delete the {{siteBuildingName}} building. Deleting this building will permanently remove it and all of its floors and rooms from InformaCast. If you have scenarios that rely on specific building settings, this building's site will no longer be included in them.",
      deleteSiteBuildingModalTitle: 'Action Cannot Be Completed',
      deleteSiteBuildingModalAlertTitle: 'Unable to remove Site Building',
      deleteSiteBuildingModalBody:
        'You cannot remove the last emergency building association while you still have standard or integration scenarios using emergency calling. Depending on your permissions or domain, you may not be able to see these scenarios.',
      reuploadSiteFloorConfirmationMessage:
        'Upload a new floor plan to replace your existing one. If you have floor and room boundaries, they will not be removed by this action.',
      deleteSiteBuildingFloorBoundaryWarningText:
        "You have chosen to delete the {{name}} floor boundary. Deleting this floor boundary will remove any room boundaries you've also made and unpin any devices you've placed on this floor plan. This action cannot be undone.",
      deleteSiteBuildingFloorPlanImageWarningText:
        "By deleting the floor plan, you will also be removing all floor and room boundaries you've made for this floor (if applicable). This cannot be undone. Would you like to continue?",
      deleteSiteFloorZoneBoundaryWarningText:
        'You have chosen to delete the {{siteBuildingFloorZone}} room boundary. Deleting this room boundary will permanently remove it from InformaCast.',
    },
    messages: {
      siteConnected: 'Site Connected',
      buildingConnected: 'Building Connected',
      floorConnected: 'Floor Connected',
      zoneConnected: 'Room Connected',
    },
    integration: {
      zoom: 'Zoom',
      'kontaktio-wearable-panic-button': 'Kontakt.io',
      sourceIntegration: '{{source}} Integration',
      externalSource: 'External Source',
    },
    search: {
      noResourcesFound: 'No {{resource}} found.',
      noResourcesExist: 'No {{resource}}. ',
    },
    communications: {
      title: 'Building Communications',
      subtitle:
        'Enable emergency calling and set your <0>Public Safety Answering Point (PSAP) information</0>.',
      imageSrc:
        'https://www.nasa.gov/sites/default/files/styles/full_width_feature/public/thumbnails/image/p22-011-147-web.jpg',
      emergencySite: {
        title: 'Emergency Building',
        subtitle:
          'Allow this building to pass address information to a public-safety answering point (PSAP) when an applicable scenario is triggered.',
      },
      card: {
        title: 'Get Started with Emergency Buildings',
        subtitle:
          'Configure an emergency building and assign your first scenario to trigger a PSAP with this tutorial.',
      },
    },
    address: {
      mismatch: 'Verify Address',
      emergency: 'Emergency Address',
      emergencySubtitle:
        'In order to make this an emergency building, it needs to have a verified address. If you are unable to resolve the address, please contact our <0>Singlewire Technical Support Team</0>.',
      useOriginal: 'Use Original Address',
      useOriginalAddressSubtitle:
        'To use the original address provided, please confirm the buildings location by moving the pin on the map to the actual location.',
      subtitle:
        'There’s a problem with the provided address. Suggested changes are highlighted in bold. Click the Save Changes button to accept these suggestions. If you are sure the address you entered is actually correct, click the Use Original Address button. Click the Cancel button to re-enter your address information and try again.',
      original: 'Original',
      suggested: 'Suggested',
      noSuggestedAddressAvailable: 'No Suggested Address Available.',
      saveChanges: 'Save Changes',
    },
  },
};

export default SitesLocale;
export const sites_t = registerResource<typeof SitesLocale.en>('sites', SitesLocale);
