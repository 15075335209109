// This file is excluded from coverage because we couldn't find a way to mock
// jQuery's growl function.
import { toast, TypeOptions } from 'react-toastify';
import { Box, Icon, Typography } from 'singlewire-components';

export interface GrowlServiceOptions {
  type?: TypeOptions | 'danger' | 'light' | 'dark';
}

const getIcon = (icon?: string) => {
  switch (icon) {
    case 'success':
      return <Icon.Success size="sm" />;
    case 'warning':
      return <Icon.Warning size="sm" />;
    case 'error':
      return <Icon.Error size="sm" />;
    case 'dark':
      return <Icon.Info color="white" size="sm" />;
    default:
      return <Icon.Info size="sm" />;
  }
};

export const Toast = (props: { icon: GrowlServiceOptions['type']; message: string }) => {
  return (
    <Box ml="sm">
      <Typography variant="subtitle" startIcon={getIcon(props.icon)}>
        {props.message}
      </Typography>
    </Box>
  );
};

/**
 * Shows a popup message to the user
 * @param {string} message the message to display to the user
 * @param {GrowlServiceOptions} opts options related to displaying the message
 */
export function growl(message: string | null, opts: GrowlServiceOptions = {}) {
  const { type = 'light' } = opts;

  if (message) {
    toast(<Toast icon={type} message={message} />, {
      position: 'top-center',
      type: type === 'danger' ? 'error' : (type as TypeOptions),
      autoClose: type === 'danger' ? 10000 : 5000,
      draggable: false,
      closeButton: true,
      hideProgressBar: true,
      style: { marginTop: '1rem' },
      icon: false,
      closeOnClick: true,
    });
  }
}

export default {
  growl,
};
