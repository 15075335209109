import { PureComponent } from 'react';
import {
  Box,
  Icon,
  Loading,
  Table,
  TableCell,
  TableRow,
  useBreakpoints,
} from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../../CommonLocale';
import { SearchAndPaginationFilters } from '../../../core/search-pager/SearchPagerActions';
import { FilterListItem, SearchBarFilterContainer } from '../SearchPager/SearchBarFilterContainer';
import { buildFilters } from '../SearchPager/SearchBarFilterUtils';
import { shared_t } from '../SharedLocale';
import { TabContext } from '../SinglewireTabsContext';
import { Alert } from './Alert';
import { LinkButton } from './Button';
import { SearchPagerComponentProps } from './TableContainer';

const enum PaginationButton {
  FirstPage,
  PreviousPage,
  NextPage,
  LastPage,
}

const SearchBarStyledDiv = styled.div<{ $showActionButton: boolean }>`
  margin-top: ${props => (props.$showActionButton ? '0rem' : '-0.65rem')};
`;

const CreateButton = ({
  actionId,
  actionIcon,
  actionLink,
  actionLabel,
}: {
  actionLink?: string;
  actionLabel?: string;
  actionIcon?: React.ReactNode;
  actionId?: string;
}) => {
  const breakpoint = useBreakpoints();

  return (
    <div>
      {['xs', 'sm'].includes(breakpoint) ? (
        <LinkButton
          id={actionId || 'create-button'}
          variant="contained"
          color="primary"
          startIcon={actionIcon || <Icon.Add />}
          label={common_t(['button', 'create'])}
          to={actionLink as string}
          aria-label={actionLabel}
        />
      ) : (
        <LinkButton
          id={actionId || 'create-button'}
          variant="contained"
          color="primary"
          startIcon={actionIcon || <Icon.Add />}
          label={actionLabel || ''}
          to={actionLink as string}
        />
      )}
    </div>
  );
};

export class SearchPager extends PureComponent<SearchPagerComponentProps> {
  static contextType = TabContext;

  onLimitChanged = (value: string) =>
    this.props.searchPagerSetLimit(this.props.pagerId, parseInt(value, 10));

  onPaginationButtonClicked = (button: any) => {
    switch (button) {
      case PaginationButton.FirstPage:
        this.props.searchPagerFirstPage(this.props.pagerId);
        break;
      case PaginationButton.LastPage:
        this.props.searchPagerLastPage(this.props.pagerId);
        break;
      case PaginationButton.NextPage:
        this.props.searchPagerNextPage(this.props.pagerId);
        break;
      case PaginationButton.PreviousPage:
        this.props.searchPagerPreviousPage(this.props.pagerId);
        break;
    }
  };

  onSearchQueryChanged = (userQuery: string | null) => {
    const opts = this.props.apiRequest && this.props.apiRequest[3];
    const baseQuery = opts && opts.params && opts.params.q;
    return this.props.searchPagerChangeQueryDebounced(
      this.props.pagerId,
      baseQuery ? { and: [baseQuery, userQuery || ''] } : userQuery || '',
      userQuery || ''
    );
  };
  onSearchPagerChangeFilters = (filters: SearchAndPaginationFilters | null) =>
    this.props.searchPagerChangeFilters(this.props.pagerId, filters);

  componentDidMount() {
    const { apiRequest, pagerId, options, disablePagerSaving, defaultValue, searchPagerCreate } =
      this.props;
    if (apiRequest) {
      searchPagerCreate(
        pagerId,
        apiRequest,
        (this.context as string) || this.props.pathname,
        {
          isLazy: options.isLazy,
          filters: buildFilters(options.filters),
          customLimits: this.props.customLimits,
          showErrorAsNotification: this.props.options.showErrorAsNotification,
        },
        undefined,
        !(defaultValue || disablePagerSaving),
        defaultValue
      );
    }
  }

  componentWillUnmount() {
    if (this.props.disablePagerSaving) {
      this.props.searchPagerDestroy(this.props.pagerId);
    }
  }

  componentDidUpdate(prevProps: SearchPagerComponentProps) {
    if (this.props.pagerId !== prevProps.pagerId && this.props.apiRequest) {
      this.props.searchPagerCreate(
        this.props.pagerId,
        this.props.apiRequest,
        (this.context as string) || this.props.pathname,
        {
          isLazy: this.props.options.isLazy,
          filters: buildFilters(this.props.options.filters),
          customLimits: this.props.customLimits,
          showErrorAsNotification: this.props.options.showErrorAsNotification,
        }
      );
    }
  }

  getPage = (pagerState: any) => {
    if (pagerState.start === 'end') {
      if (pagerState.limit < pagerState.lastPageLimit) {
        // We've switched lastPage and limit in redux so use lastPageLimit for calculation
        return Math.floor(parseInt(pagerState.data?.total, 10) / pagerState?.lastPageLimit);
      } else {
        /**
         * If we have an exact modulus of the limit we want the previous page
         * For example: limit: 10, total: 20
         * We want 11-20 (page 1 )and not 21-30 (page 2) (pages are zero indexed)
         */
        if (parseInt(pagerState.data?.total, 10) % pagerState?.limit === 0) {
          return Math.floor(parseInt(pagerState.data?.total, 10) / pagerState?.limit) - 1;
        } else {
          return Math.floor(parseInt(pagerState.data?.total, 10) / pagerState?.limit);
        }
      }
    }

    if (pagerState.limit < pagerState.lastPageLimit) {
      // We've switched lastPage and limit in redux so use lastPageLimit for calculation
      return Math.floor(parseInt(pagerState.start || '0', 10) / pagerState?.lastPageLimit);
    } else {
      // Start isn't 'end' so return the regular calculation
      return Math.floor(parseInt(pagerState.start || '0', 10) / pagerState?.limit);
    }
  };

  render() {
    const {
      pagerState,
      pagerId,
      children,
      options,
      headers,
      showActionButton,
      actionLabel,
      actionLink,
      actionIcon,
      actionId,
      additionalActions,
      customLimits,
      customControls,
      defaultValue,
    } = this.props;

    if (pagerState) {
      const tableId = `${pagerId}-table`;
      const hasError = pagerState.error;
      const hasData = pagerState.data;
      const hasDataItems = hasData && pagerState.data!.data && pagerState.data!.data.length > 0;
      const shouldShowFailureMessage = hasError;
      const shouldShowTable = !hasError && hasData;
      const shouldShowLoadingIndicator = !hasError && !hasData;
      const shouldShowEmptySearchPagerMessage = !hasError && !!hasData && !hasDataItems;

      const availableFilters = this.props.options.filters;
      const currentFilters = pagerState.filters;
      const hideSearchField = this.props.options.hideSearchField;
      const searchGridItemSize = this.props.options.searchGridItemSize;
      const onSearchPagerChangeFilters = (filters: SearchAndPaginationFilters | null) =>
        this.props.searchPagerChangeFilters(this.props.pagerId, filters);

      const start = pagerState?.start !== 'end' ? parseInt(pagerState?.start || '0', 10) : 0;

      return (
        <>
          {shouldShowFailureMessage && (
            <Alert
              id="failed-search-alert"
              color="error"
              slim={true}
              dismissable={false}
              title={shared_t(['alert', 'errorDefaultTitle'])}>
              {options.failureMessage || shared_t(['pager', 'failedSearch'])}
            </Alert>
          )}

          {shouldShowTable && (
            <Table
              headers={headers}
              id={tableId}
              hideSearch={!!hideSearchField}
              searchPlaceholder={defaultValue ? defaultValue : shared_t(['pager', 'search'])}
              initialSearch={pagerState.pagerUserQuery}
              searchGridItemSize={searchGridItemSize as any}
              actions={
                <Box align="between">
                  <Box ml="sm">
                    {availableFilters && availableFilters.length > 0 && (
                      <SearchBarStyledDiv $showActionButton={!!showActionButton}>
                        <SearchBarFilterContainer
                          searchFieldHidden={!!hideSearchField}
                          actionButtonHidden={!!showActionButton}
                          availableFilters={availableFilters}
                          onSearchPagerChangeFilters={onSearchPagerChangeFilters}
                          currentFilters={currentFilters}
                        />
                      </SearchBarStyledDiv>
                    )}
                  </Box>
                  <Box align="end">
                    {additionalActions}
                    {showActionButton && (
                      <CreateButton
                        actionId={actionId}
                        actionIcon={actionIcon}
                        actionLabel={actionLabel}
                        actionLink={actionLink}
                      />
                    )}
                  </Box>
                </Box>
              }
              filterContent={
                <div aria-live="polite" role="status">
                  {availableFilters &&
                    availableFilters.length > 0 &&
                    currentFilters &&
                    Object.keys(currentFilters).length > 0 && (
                      <Box mb="lg">
                        {availableFilters.map((filter, i) => (
                          <FilterListItem
                            key={`${filter.name}-${i}`}
                            filter={filter}
                            currentFilters={currentFilters}
                            onSearchPagerChangeFilters={onSearchPagerChangeFilters}
                          />
                        ))}
                      </Box>
                    )}
                </div>
              }
              onSearchChange={this.onSearchQueryChanged}
              paginationOptions={
                !options.disablePagination && !customControls
                  ? {
                      rowsPerPageOptions: customLimits || [10, 20, 50],
                      // We need to make sure that lastPageLimit and limit aren't switched
                      rowsPerPage: (customLimits || [10, 20, 50]).includes(pagerState.limit)
                        ? pagerState.limit!
                        : pagerState.lastPageLimit!,
                      onRowsPerPageChange: this.onLimitChanged,
                      onPaginationButtonClicked: this.onPaginationButtonClicked,
                      total: pagerState.data?.total,
                      page: this.getPage(pagerState),
                      start: pagerState?.start && pagerState.start !== 'end' ? start : undefined,
                      end:
                        pagerState.start === 'end'
                          ? pagerState.data?.total
                          : start + pagerState.limit > (pagerState.data?.total || 0)
                            ? pagerState.data?.total
                            : start + pagerState.limit,
                    }
                  : undefined
              }>
              {children}

              {shouldShowEmptySearchPagerMessage && (
                <TableRow>
                  <TableCell colSpan={headers?.length}>
                    <Alert id="empty-search-alert" slim={true} dismissable={false} color="primary">
                      {pagerState.q ? options.noSearchResultsText || '' : options.noResultsText}
                    </Alert>
                  </TableCell>
                </TableRow>
              )}

              {customControls &&
                customControls({
                  data: pagerState.data!,
                  limit: pagerState.limit,
                  onLimitChanged: (limit: number) => this.onLimitChanged(`${limit}`),
                  onFirstPage: () => this.props.searchPagerFirstPage(this.props.pagerId),
                  onLastPage: () => this.props.searchPagerLastPage(this.props.pagerId),
                  onNextPage: () => this.props.searchPagerNextPage(this.props.pagerId),
                  onPreviousPage: () => this.props.searchPagerPreviousPage(this.props.pagerId),
                })}
            </Table>
          )}

          {shouldShowLoadingIndicator && (
            <Box align="center">
              <Loading />
            </Box>
          )}
        </>
      );
    } else {
      return null;
    }
  }
}
