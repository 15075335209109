import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, timer } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { PermissionDeniedError } from '../../common-types';
import { MobileApiNotification, MobileApiPageEnvelope } from '../../mobile-api-types';
import { RootState } from '../RootReducer';
import { logout } from '../session/SessionActions';
import store from '../store';
import { BackgroundRef, req } from '../utils/api';
import { getLocalStorage, isBrowserVisible } from '../utils/common';
import { growl as liveGrowl } from '../utils/growl';
import {
  IgnoreLoadingBarTransform,
  IgnoreSessionExtendTransform,
} from '../utils/http-transformers';
import log from '../utils/log';
import {
  growl,
  initializeLayout,
  LayoutType,
  setLayout,
  startActiveNotificationsPolling,
  startNotificationsPolling,
  stopNotificationsPolling,
  updateActiveNotifications,
  updateNotifications,
} from './LayoutActions';
import { LayoutAction } from './LayoutReducer';

export const LOCAL_STORAGE_LAYOUT_STATUS_KEY = 'admin-webapp.layout-status';

const localStorage = getLocalStorage()!;

// Set layout status in localStorage.
export const setLayoutEpic: Epic<LayoutAction, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(setLayout)),
    switchMap(action => {
      localStorage.setItem(LOCAL_STORAGE_LAYOUT_STATUS_KEY, `${action.payload.layoutType}`);
      return [];
    })
  );

// Pull layout status out of localStorage and set in store.
export const initializeLayoutEpic: Epic<LayoutAction, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(initializeLayout)),
    map(() => {
      const layoutType = localStorage.getItem(LOCAL_STORAGE_LAYOUT_STATUS_KEY);
      if (layoutType == null) {
        return setLayout(LayoutType.StretchedLayout);
      } else {
        const type =
          parseInt(layoutType, 0) === LayoutType.StretchedLayout
            ? LayoutType.StretchedLayout
            : LayoutType.BoxedLayout;
        return setLayout(type);
      }
    })
  );

// Growl a real message
export const growlEpic: Epic<LayoutAction, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(growl)),
    switchMap(action => {
      liveGrowl(action.payload.message, action.payload.opts);
      return [];
    })
  );

// Periodically get notifications every 30s starting immediately
export const refreshNotificationsEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(startNotificationsPolling)),
    switchMap(() =>
      timer(0, 30000).pipe(
        filter(() => isBrowserVisible()),
        switchMap(() =>
          req<MobileApiPageEnvelope<MobileApiNotification>>(
            [
              'notifications',
              [],
              'GET',
              {
                transforms: [IgnoreSessionExtendTransform],
                params: { limit: 20, 'include-total': false },
              },
            ],
            store,
            BackgroundRef
          ).pipe(
            map(response => updateNotifications(response.data)),
            catchError(error => {
              if (!(error instanceof PermissionDeniedError)) {
                log.error('Failed to load most recent notifications', error);
              }
              return EMPTY;
            })
          )
        ),
        takeUntil(action$.pipe(ofType(stopNotificationsPolling)))
      )
    )
  );

// Periodically get notifications every 30s starting immediately
export const refreshActiveNotificationsEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(startActiveNotificationsPolling)),
    switchMap(() =>
      timer(0, 30000).pipe(
        filter(() => isBrowserVisible()),
        switchMap(() =>
          req<MobileApiPageEnvelope<MobileApiNotification>>(
            [
              'notifications',
              [],
              'GET',
              {
                transforms: [IgnoreSessionExtendTransform, IgnoreLoadingBarTransform],
                params: {
                  limit: 1,
                  createdAt_gte: new Date(new Date().getTime() - 1000 * 60 * 60 * 24).toISOString(),
                  filterNotificationStatuses: 'active',
                  'include-total': false,
                },
              },
            ],
            store,
            BackgroundRef
          ).pipe(
            map(response => updateActiveNotifications(response.data)),
            catchError(error => {
              if (!(error instanceof PermissionDeniedError)) {
                log.error('Failed to load most recent notifications', error);
              }
              return EMPTY;
            })
          )
        ),
        takeUntil(action$.pipe(ofType(logout)))
      )
    )
  );

export default combineEpics(
  setLayoutEpic,
  initializeLayoutEpic,
  growlEpic,
  refreshNotificationsEpic,
  refreshActiveNotificationsEpic
);
