import { AxiosResponse } from 'axios';
import { Epic } from 'redux-observable';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { Action, isActionOf } from 'typesafe-actions';
import { BRANDINGS_ID } from '../../constants';
import { MobileApiBranding } from '../../mobile-api-types';
import { RootState } from '../RootReducer';
import store from '../store';
import { BackgroundRef, req } from '../utils/api';
import { PermissionDeniedError } from '../../common-types';
import { getSelfRegistrationLogo } from '../utils/fileUploadUtils';
import log from '../utils/log';
import { fetchBranding, setBranding } from './BrandingActions';

/**
 * Fetch the custom primary color, secondary color, and logo size from the API
 */
export const fetchBrandingEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(fetchBranding)),
    switchMap(() =>
      req<MobileApiBranding>(['brandings', [BRANDINGS_ID], 'GET'], store, BackgroundRef).pipe(
        switchMap((response: AxiosResponse<MobileApiBranding>) => {
          const {
            primaryColor,
            secondaryColor,
            logo: logoSize,
            logoAltText,
            customUrl: customHelpUrl,
          } = response.data;

          const logoPath = (logoSize && getSelfRegistrationLogo(response.data)) || null;

          return [
            setBranding({
              primaryColor,
              secondaryColor,
              logoPath,
              logoAltText,
              customHelpUrl,
              fetchingBranding: false,
            }),
          ];
        }),
        catchError(error => {
          if (!(error instanceof PermissionDeniedError)) {
            log.error('Failed to fetch branding information', error);
          }
          return [];
        })
      )
    )
  );

export default fetchBrandingEpic;
