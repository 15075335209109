import { AxiosError } from 'axios';
import includes from 'lodash/includes';
import truncate from 'lodash/truncate';
import { SyntheticEvent } from 'react';
import { Action } from 'redux';
import { common_t } from '../../CommonLocale';
import { MobileApiValidationError } from '../../mobile-api-types';
import { isValidValidationError } from '../forms/FormUtils';
import { growl } from '../layout/LayoutActions';
import log from './log';
// General util functions

export const transform = (value: string, args?: any[] | any): string | null =>
  truncate(value, args);

/* tslint:disable */

/**
 * Generates a random unique UUID
 * @return {string} a uuid string
 */
export function generateUUID(): string {
  let currentTime = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (currentTime + Math.random() * 16) % 16 | 0;
    currentTime = Math.floor(currentTime / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

/**
 * Determines whether the provided string is a valid UUID
 * https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
 */
export function isUUID(id: string): boolean {
  return new RegExp(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/,
    'i'
  ).test(id);
}

/**
 * Check whether a resource has 'put' permission to be editable
 * If state is passed we check wheather a resource is shared and, if it is, whether we are acting in global to determine editing perms
 * @param resource Any resource that has MobileApiResourcePermissions
 * @returns {boolean} if resource is editable
 */
export function isResourceEditable(resource?: any | null, inGlobal?: boolean): boolean {
  return inGlobal
    ? !(resource && !includes(resource.permissions, 'put')) || !(resource?.isShared || inGlobal)
    : !(resource && !includes(resource.permissions, 'put'));
}

/**
 * Given an axios error, return a series of growl messages and parse the failure.
 * @param error the axios error
 * @param genericFailureMessage the generic failure message if the response didn't not include a failure message
 */
export function processErrors(
  error: AxiosError | any,
  genericFailureMessage: string = common_t(['message', 'operationFailed'])
): Action[] {
  if (error && error.response && error.response.status === 400) {
    const validationError = error.response.data;
    if (isValidValidationError(validationError)) {
      const reasons: MobileApiValidationError[] = validationError.reason;
      return reasons.map(reason => growl(reason.message, { type: 'danger' }));
    } else {
      log.error('Invalid validation error format. Using default message', error);
      return [growl(genericFailureMessage, { type: 'danger' })];
    }
  } else {
    log.error('Request failed', error);
    return [growl(genericFailureMessage, { type: 'danger' })];
  }
}

/* eslint-disable @typescript-eslint/no-empty-function */
export function noop(...args: any[]): void {}

/**
 * Utility function to determine if a browser window is in focus/visible. Useful to put here for
 * mocking purposes.
 */
export function isBrowserVisible() {
  return document.visibilityState === 'visible';
}

/**
 * Hides the main page loader to reveal the actual web application
 */
export function hidePageLoader() {
  const pageLoader = window.document.getElementsByClassName('page-loader')[0];
  if (pageLoader) {
    pageLoader.classList.add('animated');
    pageLoader.classList.add('fadeOut');
    setTimeout(() => pageLoader.parentNode && pageLoader.parentNode.removeChild(pageLoader), 1000);
  }
}

/**
 * Get local storage
 * @returns window.localStorage, if permission is granted to access it
 */
export function getLocalStorage() {
  try {
    return window.localStorage;
  } catch {
    /** Any error here means the app is done for, which is handled by an error message we're showing the user */
  }
  return undefined;
}

/**
 * As of React 17, event delegation has changed.  The Bootstrap Dropdown onToggle
 * handler now listens for "root close" events which cause the onToggle handler
 * function to fire twice (and thus keep the dropdown from ever staying open).
 * This utility function ignores events that are associated with 'rootClose'
 * until there is a better solution available.
 *
 * Bug ref - https://github.com/react-bootstrap/react-bootstrap/issues/5409
 *
 * @param isOpen - the value of "open" passed as the first argument by the Bootstrap Dropdown component
 * @param event - the click event passed as the second argument by the Bootstrap Dropdown component.
 *                As of React 17, the "rootClose" event is also passed with the Synthetic Event
 * @param meta - the metadata (passed as the third argument by the Bootstrap Dropdown component)
 *               specifying the event source.  We'll ignore 'rootClose' for now to prevent dropdown
 *               handler firing twice
 * @param handler - the function we actually want to call when the dropdown is clicked
 */
export const processDropdownToggle = (
  isOpen: boolean,
  event: SyntheticEvent,
  meta: { source: 'select' | 'click' | 'rootClose' | 'keydown' },
  handler: () => void
) => {
  if (meta.source !== 'rootClose') {
    handler();
  }
};

export const debounce = (func: Function, timeout = 300) => {
  let timer: any;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const debounceLeading = (func: Function, timeout = 300) => {
  let timer: any;
  return (...args: any) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
};

/* tslint:enable */
