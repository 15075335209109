import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Field } from 'redux-form';
import { Box, COLORS, Icon, IconButton, Typography, useBreakpoints } from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../../CommonLocale';
import { BrandingContext } from '../../../core/branding/BrandingContext';
import { SinglewireForm } from '../../../core/forms/SinglewireForm';
import Routes from '../../../core/route/Routes';
import { getProviderIdFromRoute, isSelfServiceMode } from '../../../core/utils/session';
import { PageTitle } from '../../shared/PageTitle';
import { Button } from '../../shared/components/Button';
import { Anchor } from '../../shared/components/Link';
import { Paper } from '../../shared/components/Paper';
import { TextField } from '../../shared/components/redux-form/TextField';
import { LoginFormProps } from './LoginContainer';
import { login_t } from './LoginLocale';

export const FORM_ID = 'login-form';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const LeftContainer = styled.div<{ $full?: boolean; $primaryColor: string }>`
  display: flex;
  align-items: center;
  width: ${props => (props.$full ? '100vw' : '50vw')};
  height: 100%;
  background: ${props => props.$primaryColor};
  justify-content: center;
`;

const RightContainer = styled.div<{ $secondaryColor: string }>`
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: ${props => props.$secondaryColor};
`;

const MarketingContainerTop = styled.div`
  height: 30%;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const MarketingContainerBottom = styled.div`
  height: 70%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LoginContainer = styled.div<{ full?: boolean }>`
  display: block;
  padding: 4rem;
  padding-top: 0rem;
`;

const H1 = styled.h1`
  color: ${COLORS.BLUE_60};
`;

export const Login = ({
  isLoggedIn,
  handleSubmit,
  redirectToApiLogin,
  destination,
  submitting,
}: LoginFormProps) => {
  const breakpoint = useBreakpoints();
  const providerId = getProviderIdFromRoute();
  const context = useContext(BrandingContext);
  const hideMarketingAd = isSelfServiceMode() || !!getProviderIdFromRoute();
  return (
    <>
      {isLoggedIn && <Redirect to={Routes.Home} />}

      {!isLoggedIn && (
        <>
          <PageTitle title={login_t(['pageTitle'])} />
          <Container>
            <LeftContainer
              $full={hideMarketingAd || ['xs', 'sm'].includes(breakpoint)}
              $primaryColor={context.primaryColor ?? COLORS.PRIMARY}>
              <LoginContainer full={hideMarketingAd || ['xs', 'sm'].includes(breakpoint)}>
                <Box isFlex={false}>
                  <Paper>
                    <Box mb="md" align="center">
                      <Typography variant="helptext">{login_t(['loginPrompt'])}</Typography>
                    </Box>
                    <SinglewireForm
                      formId={FORM_ID}
                      noValidate={true}
                      onSubmit={handleSubmit(values => {
                        redirectToApiLogin(destination ?? '', values.email, providerId);
                      })}>
                      <Field
                        id="email"
                        name="email"
                        aria-label={common_t(['label', 'email'])}
                        component={TextField}
                        xs={12}
                        placeholder={common_t(['label', 'email'])}
                        startIcon={<Icon.Email color="inherit" size="sm" />}
                        autoFocus={true}
                        actions={
                          <Box m="sm" mt="none">
                            <IconButton
                              label="Submit"
                              loading={submitting}
                              icon={<Icon.NavigateTo />}
                              variant="contained"
                              color="primary"
                              type="submit"
                            />
                          </Box>
                        }
                      />
                    </SinglewireForm>
                    <Trans i18nKey="login:termsOfUse">
                      {/* eslint-disable jsx-a11y/anchor-has-content */}
                      <Anchor href="https://www.singlewire.com/eula" />
                    </Trans>
                    {isSelfServiceMode() && (
                      <>
                        <p />
                        <Typography variant="subtitle">
                          {login_t(['noAccount'])}
                          <Button
                            label={login_t(['signUpHere'])}
                            size="md"
                            color="primary"
                            id="sign-up-button"
                            onClick={() => redirectToApiLogin('', '', providerId)}
                          />
                        </Typography>
                      </>
                    )}
                  </Paper>
                </Box>
              </LoginContainer>
            </LeftContainer>
            {!hideMarketingAd && !['xs', 'sm'].includes(breakpoint) && (
              <RightContainer
                id="heythere"
                $secondaryColor={context.secondaryColor ?? COLORS.GRAY_10}>
                <MarketingContainerTop>
                  <H1>{login_t(['promoHeader'])}</H1>
                  <Box align="center" my="md">
                    <Typography variant="body">{login_t(['promoText'])}</Typography>
                  </Box>

                  <Box align="center">
                    <Button
                      id="learn-more-button"
                      variant="outlined"
                      label={login_t(['learnMoreButton'])}
                      asChild>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.singlewire.com/s/whats-new">
                        {login_t(['learnMoreButton'])}
                      </a>
                    </Button>
                  </Box>
                </MarketingContainerTop>
                <MarketingContainerBottom>
                  <Box align="center">
                    <img src="assets/images/login-graphic.png" alt="Login" />
                  </Box>
                </MarketingContainerBottom>
              </RightContainer>
            )}
          </Container>
        </>
      )}
    </>
  );
};
