import { registerResource } from '../utils/localization';

export const ReportsLocale = {
  en: {
    report: 'Report',
    userSubgroups: 'User Subsets',
    subgroupsSubtitle:
      'Users must appear in a selected subset at least once to be included in the report.',
    alert:
      'This report will only return the first 10,000 entries based on the supplied filters. Consider narrowing down the results by using the filters below.',
    booleans: {
      true: 'Yes',
      false: 'No',
    },
    notificationHistory: {
      subtitle__HTML__: 'Showing notifications sent from {{from}} through {{to}}',
      shownNotifications:
        'Showing notifications that have been sent to at least one of the following channels.',
      title: 'Notification History Reports',
      create: 'Create Notification History Report',
      view: 'View Notification History Report',
      viewSubtitle:
        "View, sort, and search for notifications. Select a notification's <strong>Created At</strong> link to visit its Notification Details page.",
      subtitle:
        'Select a date range of notifications and either all notifications or a subset of them (by user or channel attributes) and view their details, e.g. subject, number of recipients, channel successes/failures, etc.',
      subsetOptions: 'Subset Options',
      showSubsets: 'Show Subset Options',
      leaveBlank: 'Leave blank for a report on all notifications.',
      channelSubgroups: 'Channel Subsets',
      groupsViewNote:
        'This report consists of all users that are a part of the following entities at least once',
      channelSubtitle:
        'Select one or more of the following channel subset filters to include notifications that use at least one of the channels.',
      subgroups:
        'Select one of the following user subset filters. Users must appear in a selected subset at least once to be included in the report.',
      userNote: 'This report is for the following user',
    },

    anonymous: {
      title: 'Anonymous User Reports',
      subtitle:
        'Select all anonymous users or a subset of them (by distribution list subscription) and view their details, e.g. registered devices, subscriptions, subscribe tags, etc.',
      distribtionListSubtext:
        'Users must appear in a selected distribution list at least once to be included in the report.',
      viewTitle: 'View Anonymous User Report',
      viewSubtitle:
        'View, sort, and search for anonymous users by the available attributes. Select any or all anonymous users and delete them from InformaCast.',
      viewAnonymousDescription:
        'This report consists of all anonymous users included in the following subset(s) at least once:',
      selectAll: 'Select All Anonymous Users',
      deleteText:
        'You have chosen to delete the selected anonymous users. Deleting these users will permanently remove them from InformaCast.',
      showingAllAnonymousUsers: 'Showing all anonymous users',
      distributionListHelpText:
        'Only anonymous users currently subscribed to a distribution list count against your​​ license.',
    },
    users: {
      card: {
        usersTitle: 'Users',
        usersSubtitle: 'Select the users to populate this report.',
        selectUsers: 'Select Users',
        selectAttributes: 'Select Attributes',
        noUsersSelected: 'No Users Selected',
        noUsersSelectedRationale: "Click Select Users to define this report's parameters",
        allUsers: 'All Users',
      },
      userAttributes: {
        title: 'User Attributes',
        subtitle: 'Select the user attributes to populate this report.',
        atLeastOneRequired: 'Please select at least one user attribute',
        isLocked: 'Is Locked',
        passwordResetRequired: 'Password Reset is Required',
        dnd: 'Do Not Disturb Enabled',
        devicesDisabled: 'Devices Disabled',
        devicesExcluded: 'Devices Excluded',
        distributionLists: 'Distribution Lists',
        securityGroups: 'Security Groups',
        smsDevices: 'SMS Devices',
        callDevices: 'Phone Call Devices',
        emailDevices: 'Email Devices',
        pushDevices: 'Push Devices',
        userSource: 'User Source',
      },
      title: 'User Report',
      subtitle:
        "Generate a report of individual users' attributes, e.g. name, email address, distribution list subscriptions, device types and their details, etc.",
      usersCardTitle: 'Users',
      create: 'Create User Reports',
      createSubtitle:
        'Select all individual users or a subset of them (by distribution list and/or security group) and view their details, e.g. name, subscriptions, memberships, device count, etc.',
      resultsTitle: 'Results',
      resultsSubtitle:
        "View the users who match your report's parameters by name, email address, and user attribute(s).",
      noReportResults: "No users match your report's specified parameters.",
      viewTitle:
        'View, sort, and search for users by the available attributes. Select any or all users and delete them from InformaCast.',
      viewSubtitle:
        'This report consists of all users included in the following subset(s) at least once:',
      parametersTitle: 'Parameters',
      parametersSubtitle:
        "Review the users and user attributes you selected to define this report's parameters.",
      showingAllUsers: 'Showing all users',
      deviceRegistration: 'Device Registration',
      userResourceName: '{{identifier}}', // do we need to worry about api-sourced names being sanitized? does this work?
      userAttributeToAttributeLabel: {
        callDevices: 'Call Device',
        emailDevices: 'Email Device',
        pushDevices: 'Push Device',
        smsDevices: 'SMS Device',
        distributionLists: 'Distribution List',
        securityGroups: 'Security Group',
        'local-loader': 'Local User Loader',
        'cloud-loader': 'Cloud User Loader',
        manual: 'Manually Added',
      },
    },

    auditEvents: {
      title: 'Change History Report',
      subtitle:
        'Generate a report of changes to a resource type, such as message templates that were created, edited, or deleted.',
      card: {
        resourceTitle: 'Resource',
        resourceSubtitle: 'Select the resource type to populate this report.',
        dateRangeTitle: 'Date Range',
        dateRangeSubtitle: "Select the date range you'd like to populate this report",
      },
      resourceList: {
        title: 'Resource Type',
        placeHolder: 'Select Resource',
      },
      filter: {
        name: 'Name',
        status: 'Status',
        error: 'Error',
        lastUpdatedBy: 'Last Updated By',
        unknown: 'Unknown',
      },
      displayColumn: {
        name: 'Name',
        status: 'Status',
        error: 'Error',
        date: 'Date',
        updatedBy: 'Updated By',
        ancestorType: 'Type {{num}}',
        ancestorName: 'Belongs To {{num}}',
        eventId: 'Event Id',
      },
      targetColumn: {
        type: 'Type',
        name: 'Belongs To',
      },
      limitMessage:
        'This report will only return the first {{limit}} entries based on the supplied filters.',
      maxResultsReachedMessage:
        'This report only returns the first {{limit}} entries based on the supplied search parameters.',
      noParametersProvided: 'No search parameters provided',
      parametersTitle: 'Parameters',
      parametersSubtitle:
        "Review the resource and date range you selected to define this report's parameters.",
      resultsTitle: 'Results',
      resultsSubtitle:
        'View the resource type that matches your report’s parameters by name, status, date, and the user who made the change.',
      noReportResults: "No events match your report's specified parameters.",
      eventsLower: 'events',
      modal: {
        title: 'Change History',
      },
    },

    label: {
      generateReport: 'Generate Report',
      parameters: 'Parameters',
      result: 'Result',

      createdAt: 'Created At',
      sender: 'Sender',
      subject: 'Subject',
      recipients: 'Recipients',
      sms: 'SMS',
      smsFailed: 'SMS Failed',
      call: 'Call',
      callFailed: 'Call Failed',
      email: 'Email',
      emailFailed: 'Email Failed',
      push: 'Push',
      pushFailed: 'Push Failed',
      registeredDevices: 'Registered Devices',
      subscribeTags: 'Subscribe Tags',
      notificationEndDates: 'Notification End Dates',
      select: 'Select',
      pushDevices: 'Push Devices',
      pushDevice: 'Push Device',
      emailDevices: 'Email Devices',
      callDevices: 'Phone Call Devices',
      smsDevices: 'SMS Devices',
      doNotDisturb: 'Do Not Disturb',
      doNotDisturbEnabled: 'Do Not Disturb Enabled',
      userIdEmail: 'User ID Email',
      devicesDisabled: 'Devices Disabled',
      devicesExcluded: 'Devices Excluded',

      expandAll: 'Expand All',
      shrinkAll: 'Shrink All',
      selectAll: 'Select All',
      deselectAll: 'Deselect All',

      phoneCallLabel: 'Phone Call',
      smsLabel: 'SMS',
      emailLabel: 'Email',
      pushLabel: 'Push',
      callLabel: 'Call',
      smsOptionLabel: 'SMS',
      emailOptionLabel: 'Email',
      pushOptionLabel: 'Push',
      callOptionLabel: 'Phone Call',
      deviceCount: 'Device Count',
      dndEnabled: 'DND Enabled',
      usersSettingsEnabled: 'Users with Settings Enabled',
      userSource: 'User Source',

      distributionListNum: 'Distribution List {{num}}',
      securityGroupNum: 'Security Group {{num}}',
      smsNum: 'SMS {{num}}',
      callNum: 'Call {{num}}',
      emailNum: 'Email {{num}}',
      pushNum: 'Push {{num}}',
      dndFormat: 'Yes ({{start}} - {{end}})',
      distributionLists: 'Distribution Lists: ',
      securityGroups: 'Security Groups: ',
      user: 'User: ',
      requiredLabel: 'Please choose at least one of these groups to view on the report',
      invalidDateRange: 'Please select a valid date range',
      dateRange: 'Date Range',
      dateSubtitle: 'Leave blank to keep start at 12 months ago or end date unbounded.',
      beginningOfRecords: 'Beginning of Records',
      endOfRecords: 'End of Records',
      invalidDateRangeOneYearBound: 'Date must be within the last 12 months',
      invalidDateRange18MonthsBound: 'Date must be within the last 18 months',
      excluded: 'Excluded',
      excludedFromUserLoaderWithTimestamp:
        'Device excluded by user action on {{timestamp}}; no longer synced by user loader.',
      excludedFromUserLoader: 'Device excluded by user action; no longer synced by user loader.',
      additionalDetails: 'Additional Details',
    },
    confirmationReport: {
      response: 'Response',
      noResponse: 'No Response',
      title: 'Confirmation Reports',
      subtitle__HTML__: 'Showing confirmation responses sent from {{from}} through {{to}}',
      create: 'Create Confirmation Report',
      createSubtitle: 'Select a date range of confirmation repsonses and view their details.',
      view: 'View Confirmation Report',
      viewTitle:
        'View, sort, and search for confirmation reponses for notifications by the available attributes.',
      viewSubtitle:
        'This report consists of all confirmation responses included in the dates specified.',
    },
  },
};

export default ReportsLocale;
export const reports_t = registerResource<typeof ReportsLocale.en>('reports', ReportsLocale);
