import { AOIMultiValueLabelAction } from './AOIMultiValueLabelReducer';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { RootState } from '../RootReducer';
import { isActionOf } from 'typesafe-actions';
import { showAOIPreview } from './AOIMultiValueLabelAction';
import { filter, switchMap } from 'rxjs/operators';
import { showModal } from '../modal/ModalActions';

export const showAOIPreviewEpics: Epic<
  AOIMultiValueLabelAction,
  Action,
  RootState,
  any
> = action$ =>
  action$.pipe(
    filter(isActionOf(showAOIPreview)),
    switchMap(action => [showModal({ id: action.payload.modalId })])
  );

export default showAOIPreviewEpics;
