import { createAction } from 'typesafe-actions';
import { MobileApiAreaOfInterest } from '../../mobile-api-types';

// Handled in: Reducer and Epics
export const showAOIPreview = createAction(
  '[AOI MULTI SELECT] Show AOI Preview',
  (aoi: MobileApiAreaOfInterest, modalId: string) => ({ aoi, modalId })
)();

// Handled in: Reducer
export const clearPreviewAOI = createAction('[AOI MULTI SELECT] Clear Preview AOI')();
