import { createAction } from 'typesafe-actions';
import { ApiRequest } from '../../common-types';
import { SearchPagerState } from './SearchPagerReducer';

export type SingleSelectFilterTypes = 'date' | 'string' | 'attribute' | 'location';

export interface SingleSelectFilter {
  type: SingleSelectFilterTypes;
  value: string;
}

export type MultiSelectFilterType = 'multi';

export interface MultiSelectFilter<T> {
  type: MultiSelectFilterType;
  value: Array<T>;
  transformFilterValue?: (value: Array<T>) => string | Array<any>;
}

export type AllFilterTypes = MultiSelectFilter<any> | SingleSelectFilter;

export interface SearchAndPaginationFilters {
  [QueryParamName: string]: AllFilterTypes;
}

export interface FiltersAsQueryParams {
  [QueryParamName: string]: AllFilterTypes['value'];
}

interface BaseSearchPagerExecuteParams {
  q: string | null | { and: string[] };
  start: string | null;
  limit: number;
  lastPageLimit: number | null;
  previousFromLastPage: boolean;
  silentRefresh?: boolean;
}

export interface SearchPagerExecuteParams extends BaseSearchPagerExecuteParams {
  filters: SearchAndPaginationFilters | null;
}

export interface FormattedSearchPagerParams extends BaseSearchPagerExecuteParams {
  filters: FiltersAsQueryParams | null;
}

export interface SearchAndPaginationOptions {
  filters?: SearchAndPaginationFilters | null;
  // Don't refresh the search pager right after creation
  isLazy?: boolean;
  customLimits?: [number, number, number];
  /** Shows the table error as a toast notification */
  showErrorAsNotification?: boolean;
}

export interface SearchPagerLimitOptions {
  keepGlobalLimit: boolean;
}

export type SearchPagerId = string;

export const DEFAULT_SEARCH_DEBOUNCE_MS = 300;

// Handled in: Reducer
// Creates pager in store.
export const searchPagerCreated = createAction(
  '[Search Pager] Created',

  (
    pagerId: SearchPagerId,
    apiRequest: ApiRequest,
    route: string,
    options?: SearchAndPaginationOptions
  ) => ({
    pagerId,
    apiRequest,
    route,
    options,
  })
)();

// Handled in: Epic
export const removePagerDataFromLocalStorage = createAction(
  '[Search Pager] Remove Pager Data From Local Storage'
)();

// Handled in: Reducer
// Removes pager from store.
export const searchPagerDestroyed = createAction(
  '[Search Pager] Destroyed',
  (pagerId: SearchPagerId) => ({ pagerId })
)();

// Handled in: Reducer,
// Updates pager in store.
export const searchPagerUpdated = createAction(
  '[Search Pager] Updated',
  (pagerId: SearchPagerId, state: Partial<SearchPagerState<any>>) => ({
    pagerId,
    state,
  })
)();

// Handled in: Epic
// Updates search pager state in the searchPagerSubscriptions object.
export const searchPagerExecute = createAction(
  '[Search Pager] Execute',
  (pagerId: SearchPagerId, params: SearchPagerExecuteParams) => ({
    pagerId,
    params,
  })
)();

// Handled in: Epic
// Create searchPagerSubscription and set initial params.
export const searchPagerCreate = createAction(
  '[Search Pager] Create',
  (
    pagerId: SearchPagerId,
    apiRequest: ApiRequest,
    pathname: string,
    options?: SearchAndPaginationOptions,
    responseDataFn?: (data: any[]) => any[],
    shouldRestoreSavedPager?: boolean,
    defaultValue?: string | undefined // Prefills the searchpager searchbox with text
  ) => ({
    pagerId,
    apiRequest,
    options,
    responseDataFn,
    pathname,
    shouldRestoreSavedPager,
    defaultValue,
  })
)();

// Handled in: Epic
// Properly close and remove the searchPagerSubscription.
export const searchPagerDestroy = createAction(
  '[Search Pager] Destroy',
  (pagerId: SearchPagerId, shouldRestoreSavedPager?: boolean) => ({
    pagerId,
    shouldRestoreSavedPager,
  })
)();

// Handled in: Epic
// Update the SearchPagerExecuteParams for the pager to change the search query.
export const searchPagerChangeQuery = createAction(
  '[Search Pager] Change Query',
  (pagerId: SearchPagerId, q: string | { and: string[] }, userQuery = '') => ({
    pagerId,
    q,
    userQuery,
  })
)();

// Handled in: Epic
// Makes sure the user has paused in typing before actually changing the search query,
// so as not to generate too many network requests.
export const searchPagerChangeQueryDebounced = createAction(
  '[Search Pager] Change Query Debounced',
  (pagerId: SearchPagerId, q: string | { and: string[] }, userQuery = '') => ({
    pagerId,
    q,
    userQuery,
  })
)();

// Handled in: Epic
// Update the SearchPagerExecuteParams for the pager to change the search filters.
export const searchPagerChangeFilters = createAction(
  '[Search Pager] Change Filters',
  (pagerId: SearchPagerId, filters: null | SearchAndPaginationFilters) => ({
    pagerId,
    filters,
  })
)();

// Handled in: Epic
// Updates the SearchPagerExecuteParams after a change is made (e.g. a delete).
export const searchPagerRefresh = createAction(
  '[Search Pager] Refresh',
  (pagerId: SearchPagerId, totalDelta = 0, params?: { silentRefresh: boolean }) => ({
    pagerId,
    totalDelta,
    params,
  })
)();

// Handled in: Epic
// Update the SearchPagerExecuteParams for the pager to change the pager limit
// (how many items to display at a time).
export const searchPagerSetLimit = createAction(
  '[Search Pager] Set Limit',
  (pagerId: SearchPagerId, limit: number, limitOptions?: SearchPagerLimitOptions) => ({
    pagerId,
    limit,
    limitOptions,
  })
)();

// Handled in: Reducer
// Update the defaultValue so that the pagerUserQuery isn't overwritten
export const setUserQuery = createAction(
  '[Search Pager] Set User Query',
  (pagerId: SearchPagerId, userQuery: string) => ({
    pagerId,
    userQuery,
  })
)();

// Handled in: Epic
// Update the SearchPagerExecuteParams for the pager to go to the previous page.
export const searchPagerPreviousPage = createAction(
  '[Search Pager] Previous Page',
  (pagerId: SearchPagerId) => ({ pagerId })
)();

// Handled in: Epic
// Update the SearchPagerExecuteParams for the pager to go to the last page.
export const searchPagerLastPage = createAction(
  '[Search Pager] Last Page',
  (pagerId: SearchPagerId, params?: { silentRefresh: boolean }) => ({ pagerId, params })
)();

// Handled in: Epic
// Update the SearchPagerExecuteParams for the pager to go to the nth page.
export const searchPagerFirstPage = createAction(
  '[Search Pager] First Page',
  (pagerId: SearchPagerId) => ({ pagerId })
)();

// Handled in: Epic
// Update the SearchPagerExecuteParams for the pager to go to the next page.
export const searchPagerNextPage = createAction(
  '[Search Pager] Next Page',
  (pagerId: SearchPagerId) => ({ pagerId })
)();
