import flow from 'lodash/fp/flow';
import merge from 'lodash/fp/merge';
import omit from 'lodash/fp/omit';
import pickBy from 'lodash/fp/pickBy';
import get from 'lodash/get';
import identity from 'lodash/identity';
import { combineEpics, Epic, StateObservable } from 'redux-observable';
import { EMPTY, pipe, Subject, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { ActionCreator } from 'typesafe-actions/dist/is-action-of';
import { ApiRequest } from '../../common-types';
import { DEFAULT_PAGER_LIMIT } from '../../constants';
import { MobileApiPageEnvelope } from '../../mobile-api-types';
import { PAGER_ID as DomainSelectPagerID } from '../../views/layout/DomainSelect';
import { PAGER_ID as QuickSendPagerID } from '../../views/layout/QuickSend';
import { growl } from '../layout/LayoutActions';
import { locationChangeComplete } from '../navigation/NavigationActions';
import { RootState } from '../RootReducer';
import store from '../store';
import { BackgroundRef, req } from '../utils/api';
import {
  IgnoreLoadingBarTransform,
  IgnoreSessionExtendTransform,
} from '../utils/http-transformers';
import log from '../utils/log';
import {
  DEFAULT_SEARCH_DEBOUNCE_MS,
  FormattedSearchPagerParams,
  removePagerDataFromLocalStorage,
  searchPagerChangeFilters,
  searchPagerChangeQuery,
  searchPagerChangeQueryDebounced,
  searchPagerCreate,
  searchPagerCreated,
  searchPagerDestroy,
  searchPagerDestroyed,
  searchPagerExecute,
  searchPagerFirstPage,
  SearchPagerId,
  searchPagerLastPage,
  searchPagerNextPage,
  searchPagerPreviousPage,
  searchPagerRefresh,
  searchPagerSetLimit,
  searchPagerUpdated,
} from './SearchPagerActions';
import { loadPagerLimit, savePagerLimit } from './SearchPagerLocalStorage';
import { checkPagerLimits, SearchPagerAction, SearchPagerState } from './SearchPagerReducer';
import { transformFiltersToQueryParams } from './SearchPagerUtils';

// Here we store a map of pagerId to a tuple of FormattedSearchPagerParams and a subscription.
// When we create a search pager, we start a subscription (and store it here) that watches
// the FormattedSearchPagerParams. Every time the FormattedSearchPagerParams gets updated, the
// chain of events in `startSearchPager` gets run - a network request gets new data from the
// server and updates the store.  This is local state because Subscriptions are not serializable.
export const searchPagerSubscriptions: {
  [pagerId: string]: [Subject<FormattedSearchPagerParams>, Subscription];
} = {};

// Makes sure we are operating on a pager that exists in searchPagerSubscriptions and the store
// (i.e., that it has been created correctly).
function getAndFilterValidPager<A extends { type: string }, T1 extends A>( // This is so we can dynamically filter and keep typescript happy
  allowCurrentlySearching: boolean,
  allowedType: ActionCreator<T1>,
  state$: StateObservable<RootState>
) {
  return pipe(
    filter(isActionOf(allowedType)),
    withLatestFrom(state$.pipe(map(s => s.searchPagers))),
    map(
      ([action, pagerState]): [T1, SearchPagerState<any>, Subject<FormattedSearchPagerParams>] => {
        const pagerId: SearchPagerId = get(action, ['payload', 'pagerId']); // This is a hack around type T1 not having a `payload` property
        return [
          action,
          pagerState[pagerId],
          searchPagerSubscriptions[pagerId] && searchPagerSubscriptions[pagerId][0],
        ];
      }
    ),
    filter(
      ([action, pager, executeSubject]) =>
        !!(pager && (allowCurrentlySearching || !pager.isSearching) && executeSubject)
    )
  );
}

// Create a Subject out of FormattedSearchPagerParams, and every time they change send a network
// request to get the new state of the pager, then update the store and perform some special handling
// if the previous pager page was the last page of the pager.
export function startAndWatchSearchPager(
  pagerId: SearchPagerId,
  apiRequest: ApiRequest,
  responseDataFn?: (data: any[]) => any[],
  showErrorAsNotification?: boolean
): [Subject<FormattedSearchPagerParams>, Subscription] {
  const executeSubject = new Subject<FormattedSearchPagerParams>();
  const subscription = executeSubject
    .pipe(
      switchMap(params => {
        if (!params.silentRefresh) {
          store.dispatch(
            searchPagerUpdated(pagerId, { ...params, isSearching: true, error: null })
          );
        }
        const queryParams = flow(
          merge(params),
          omit(['lastPageLimit', 'previousFromLastPage', 'filters']),
          pickBy(identity)
        )({});
        const [url, urlParams, method, opts = {}] = apiRequest;
        const updatedApiRequest: ApiRequest = [
          url,
          urlParams,
          method,
          {
            ...opts,
            params: { ...(opts.params || {}), ...queryParams, ...(params.filters || {}) },
            transforms: params.silentRefresh
              ? [IgnoreSessionExtendTransform, IgnoreLoadingBarTransform]
              : [],
          },
        ];
        return req<MobileApiPageEnvelope<any>>(updatedApiRequest, store, BackgroundRef).pipe(
          tap(({ data: page }) => {
            store.dispatch(
              searchPagerUpdated(pagerId, {
                data: responseDataFn
                  ? {
                      ...page,
                      data: responseDataFn(page.data),
                    }
                  : page,
                isSearching: false,
                error: null,
                limit: params.lastPageLimit || params.limit,
                lastPageLimit: params.limit,
              })
            );

            if (params.previousFromLastPage) {
              store.dispatch(
                searchPagerExecute(pagerId, {
                  ...queryParams,
                  start: page.previous,
                  limit: params.lastPageLimit,
                } as any)
              );
            }
          }),
          catchError(error => {
            log.info('There was a pagination error', error);
            if (!params.silentRefresh) {
              store.dispatch(searchPagerUpdated(pagerId, { isSearching: false, error }));
            }
            if (showErrorAsNotification) {
              store.dispatch(growl(error.response.data.reasons[0].message, { type: 'danger' }));
            }
            return EMPTY;
          })
        );
      })
    )
    .subscribe();
  // These are references to the same Subject, we use the executeSubject to emit events and
  // the subscription to cancel at a later point.
  return [executeSubject, subscription];
}

export const createEpic: Epic<SearchPagerAction, SearchPagerAction, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(searchPagerCreate)),
    switchMap(action => {
      if (!searchPagerSubscriptions[action.payload.pagerId]) {
        searchPagerSubscriptions[action.payload.pagerId] = startAndWatchSearchPager(
          action.payload.pagerId,
          action.payload.apiRequest,
          action.payload.responseDataFn,
          action.payload.options?.showErrorAsNotification
        );
      }

      // Returns as 'low' | 'medium' | 'high'
      const pagerLevel = loadPagerLimit();

      // Explode api request to optionally add localStorage pager limit if maxCount doesn't exist
      const [request, params, method, options] = action.payload.apiRequest;
      const opts = !!options
        ? {
            ...options,
            params: {
              ...options.params,
              limit: checkPagerLimits(
                options.params?.limit,
                pagerLevel,
                action.payload.options?.customLimits || null
              ),
            },
          }
        : {
            params: {
              limit: checkPagerLimits(
                DEFAULT_PAGER_LIMIT,
                pagerLevel,
                action.payload.options?.customLimits || null
              ),
            },
          };

      return [
        searchPagerCreated(
          action.payload.pagerId,
          [request, params, method, opts],
          action.payload.pathname,
          action.payload.options
        ),
        ...(action.payload.options && action.payload.options.isLazy
          ? []
          : [searchPagerRefresh(action.payload.pagerId)]),
      ];
    })
  );

export const destroyEpic: Epic<SearchPagerAction, SearchPagerAction, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(searchPagerDestroy)),
    map(action => {
      if (searchPagerSubscriptions[action.payload.pagerId]) {
        const [subject, subscription] = searchPagerSubscriptions[action.payload.pagerId];
        subscription.unsubscribe();
        subject.complete();
        delete searchPagerSubscriptions[action.payload.pagerId];
      }
      return searchPagerDestroyed(action.payload.pagerId);
    })
  );

/**
 * We only destroy pagers when routing to a sibling route instead of a child route
 *
 *  /users => /users/:id keep list pager on /users so when we are done editing we still have filters
 *  /users => /distribution-lists the users pager is destroyed along with its filters
 */
export const destroyOnSiblingRouteEpic: Epic<
  SearchPagerAction,
  SearchPagerAction,
  RootState,
  any
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(locationChangeComplete)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const destroyablePagers = Object.entries(state.searchPagers).filter(([key, value]) => {
        // Quicksend and the domain picker are special cases that we never want to destroy (they are on every page)
        return (
          key !== QuickSendPagerID &&
          key !== DomainSelectPagerID &&
          !action.payload.location.pathname.startsWith(value.route) &&
          action.payload.location.pathname !== '/reload' &&
          // Don't destroy visible pagers on a tabbed view when navigating from a righthand tab to the first tab
          // (See Incident Details for an example).
          Object.keys(state.tabs).length === 0
        );
      });

      return destroyablePagers.map(([pagerId]) => {
        return searchPagerDestroy(pagerId);
      });
    })
  );

export const changeQueryEpic: Epic<SearchPagerAction, SearchPagerAction, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    getAndFilterValidPager(true, searchPagerChangeQuery, state$),
    map(([action, pager]) =>
      searchPagerExecute(action.payload.pagerId, {
        q: action.payload.q,
        filters: pager.filters,
        start: null,
        limit: pager.limit,
        lastPageLimit: null,
        previousFromLastPage: false,
      })
    )
  );

const changeQueryDebouncedEpic: Epic<SearchPagerAction, SearchPagerAction, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    getAndFilterValidPager(true, searchPagerChangeQueryDebounced, state$),
    debounceTime(DEFAULT_SEARCH_DEBOUNCE_MS),
    map(([action]) =>
      searchPagerChangeQuery(action.payload.pagerId, action.payload.q, action.payload.userQuery)
    )
  );

export const changeFiltersEpic: Epic<SearchPagerAction, SearchPagerAction, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    getAndFilterValidPager(true, searchPagerChangeFilters, state$),
    map(([action, pager]) =>
      searchPagerExecute(action.payload.pagerId, {
        q: pager.q,
        filters: action.payload.filters,
        start: null,
        limit: pager.limit,
        lastPageLimit: null,
        previousFromLastPage: false,
      })
    )
  );

export const refreshEpic: Epic<SearchPagerAction, SearchPagerAction, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    getAndFilterValidPager(false, searchPagerRefresh, state$),
    switchMap(([action, pager]): SearchPagerAction[] => {
      const changeDataTotalActions =
        action.payload.totalDelta !== 0 && pager.data
          ? [
              searchPagerUpdated(action.payload.pagerId, {
                data: {
                  ...pager.data,
                  total: pager.data.total + action.payload.totalDelta,
                },
              }),
            ]
          : [];
      if (
        pager.start === 'end' ||
        (pager.data && pager.data.data.length < pager.limit && pager.data.total > pager.limit)
      ) {
        return [...changeDataTotalActions, searchPagerLastPage(action.payload.pagerId)];
      } else {
        return [
          ...changeDataTotalActions,
          searchPagerExecute(action.payload.pagerId, {
            q: pager.q,
            filters: pager.filters,
            start: pager.start,
            limit: pager.limit || DEFAULT_PAGER_LIMIT,
            lastPageLimit: pager.lastPageLimit,
            previousFromLastPage: pager.previousFromLastPage,
            silentRefresh: action.payload.params && action.payload.params.silentRefresh,
          }),
        ];
      }
    })
  );

export const limitEpic: Epic<SearchPagerAction, SearchPagerAction, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    getAndFilterValidPager(false, searchPagerSetLimit, state$),
    map(([action, pager]) => {
      if (!(action.payload.limitOptions && action.payload.limitOptions.keepGlobalLimit)) {
        savePagerLimit(action.payload.limit, pager.customLimits);
      }

      return searchPagerExecute(action.payload.pagerId, {
        q: pager.q,
        filters: pager.filters,
        start: null,
        limit: action.payload.limit,
        lastPageLimit: null,
        previousFromLastPage: false,
      });
    })
  );

// We need special handling if the page you are leaving is the last page in the pager.
export const previousPageEpic: Epic<SearchPagerAction, SearchPagerAction, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    getAndFilterValidPager(false, searchPagerPreviousPage, state$),
    filter(([action, pager]) => !!(pager.data && pager.data.previous)),
    map(([action, pager]) => {
      if (pager.lastPageLimit && pager.lastPageLimit < (pager.limit || 0)) {
        // we are coming from the last page and need to make some adjustments to the limit to handle remainders
        return searchPagerExecute(action.payload.pagerId, {
          q: pager.q,
          filters: pager.filters,
          start: pager.data!.previous,
          limit: pager.limit - pager.lastPageLimit,
          lastPageLimit: pager.limit,
          previousFromLastPage: true,
        });
      } else {
        // just go to the previous page in a calm and orderly fashion
        return searchPagerExecute(action.payload.pagerId, {
          q: pager.q,
          filters: pager.filters,
          start: pager.data!.previous,
          limit: pager.limit,
          lastPageLimit: null,
          previousFromLastPage: false,
        });
      }
    })
  );

export const lastPageEpic: Epic<SearchPagerAction, SearchPagerAction, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    getAndFilterValidPager(false, searchPagerLastPage, state$),
    filter(([action, pager]) => !!(pager.data && pager.data.total > pager.data.data.length)),
    map(([action, pager]) => {
      let limit = pager.data!.total % pager.limit;
      if (limit === 0) {
        limit = pager.limit;
      }
      return searchPagerExecute(action.payload.pagerId, {
        q: pager.q,
        filters: pager.filters,
        start: 'end',
        limit,
        lastPageLimit: pager.limit,
        previousFromLastPage: false,
        silentRefresh: action.payload.params && action.payload.params.silentRefresh,
      });
    })
  );

export const firstPageEpic: Epic<SearchPagerAction, SearchPagerAction, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    getAndFilterValidPager(false, searchPagerFirstPage, state$),
    filter(([action, pager]) => !!(pager.data && pager.data.previous)),
    map(([action, pager]) =>
      searchPagerExecute(action.payload.pagerId, {
        q: pager.q,
        filters: pager.filters,
        start: null,
        limit: pager.limit,
        lastPageLimit: null,
        previousFromLastPage: false,
      })
    )
  );

export const nextPageEpic: Epic<SearchPagerAction, SearchPagerAction, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    getAndFilterValidPager(false, searchPagerNextPage, state$),
    filter(([action, pager]) => !!(pager.data && pager.data.next)),
    map(([action, pager]) =>
      searchPagerExecute(action.payload.pagerId, {
        q: pager.q,
        filters: pager.filters,
        start: pager.data!.next,
        limit: pager.limit,
        lastPageLimit: null,
        previousFromLastPage: false,
      })
    )
  );

// Update the searchPagerSubscriptions.
export const executeEpic: Epic<SearchPagerAction, SearchPagerAction, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    getAndFilterValidPager(true, searchPagerExecute, state$),
    map(([action, pager, searchPagerSub]) => {
      searchPagerSub.next({
        ...action.payload.params,
        filters: transformFiltersToQueryParams(action.payload.params.filters),
      });
      return searchPagerUpdated(action.payload.pagerId, action.payload.params);
    })
  );

// Update the searchPagerSubscriptions.
export const removePagerDataFromLocalStorageEpic: Epic<
  SearchPagerAction,
  SearchPagerAction,
  RootState,
  any
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removePagerDataFromLocalStorage)),
    switchMap(() => {
      const localStorageKeys = [];

      for (let i = 0, len = localStorage.length; i < len; ++i) {
        const key = localStorage.key(i);

        if (key) {
          localStorageKeys.push(key);
        }
      }

      const pagerStorageKeys = localStorageKeys.filter(key => {
        return (
          key.startsWith('admin-webapp.') && (key.endsWith('.search') || key.endsWith('.filters'))
        );
      });

      pagerStorageKeys.forEach(key => {
        localStorage.removeItem(key);
      });

      return [];
    })
  );

export default combineEpics(
  createEpic,
  destroyOnSiblingRouteEpic,
  destroyEpic,
  changeFiltersEpic,
  changeQueryEpic,
  changeQueryDebouncedEpic,
  refreshEpic,
  limitEpic,
  previousPageEpic,
  lastPageEpic,
  firstPageEpic,
  nextPageEpic,
  executeEpic,
  removePagerDataFromLocalStorageEpic
);
