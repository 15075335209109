// A common utility for showing a search pager and taking care of tasks like search, pagination, etc.

import React, { ComponentClass, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { ApiRequest } from '../../../common-types';
import { RootState } from '../../../core/RootReducer';
import {
  SearchPagerId,
  searchPagerChangeFilters,
  searchPagerChangeQueryDebounced,
  searchPagerCreate,
  searchPagerDestroy,
  searchPagerFirstPage,
  searchPagerLastPage,
  searchPagerNextPage,
  searchPagerPreviousPage,
  searchPagerSetLimit,
} from '../../../core/search-pager/SearchPagerActions';
import { getPager } from '../../../core/search-pager/SearchPagerReducer';
import { MobileApiPageEnvelope } from '../../../mobile-api-types';
import { SearchBarProps } from '../SearchPager/SearchBar';
import { ActionsCell } from './ActionsCell';
import { SearchPager } from './Table';

interface BaseSearchPagerFilter {
  label: string;
  name: string;
  id?: string;
}

export interface SearchPagerDateFilter extends BaseSearchPagerFilter {
  localizeOption?: (option: string) => string;
  localizeName?: () => string;
  type: 'date';
  initialValue?: string | { [key: string]: string };
  // Filter must be provided, cannot be removed.
  required?: boolean;
  restrictToOneYear?: boolean;
}
export interface SearchPagerStringFilter extends BaseSearchPagerFilter {
  localizeName: () => string;
  type: 'string';
  initialValue?: string | { [key: string]: string };
}

export interface SearchPagerAttributeFilter extends BaseSearchPagerFilter {
  localizeOption: (option: string) => string;
  localizeName: () => string;
  options: string[];
  type: 'attribute';
  initialValue?: string | { [key: string]: string };
}

export interface SearchPagerMultiselectFilter<T> extends BaseSearchPagerFilter {
  localizeName: SearchPagerAttributeFilter['localizeName'];
  type: 'multi';
  // Defaults to no function call on value if not provided
  transformFilterValue?: (filter: Array<T>) => string | Array<any>;
  localizeOption: (option: T) => string;
  options: Array<T>;
}

export interface SearchPagerLocationFilter extends BaseSearchPagerFilter {
  localizeName: () => string;
  type: 'location';
  initialValue?: { [key: string]: string };
}

export type SearchPagerFilter =
  | SearchPagerDateFilter
  | SearchPagerStringFilter
  | SearchPagerAttributeFilter
  | SearchPagerLocationFilter
  | SearchPagerMultiselectFilter<any>;

export interface SearchPagerOptions {
  classOverride?: string;
  disableSearch?: boolean; // hides whole search bar including filter
  hideSearchField?: boolean; // hides only the search field
  searchGridItemSize?: number;
  disablePagination?: boolean;
  maxSearchLength?: number;
  hideSizeControl?: boolean;
  noResultsText: string;
  noSearchResultsText?: string;
  failureMessage?: string;
  isLazy?: boolean;
  filters?: SearchPagerFilter[];
  slim?: boolean;
  /** Shows the table error as a toast notification */
  showErrorAsNotification?: boolean;
}

export interface TableContainerHeader {
  attribute: string;
  label: string;
  align?: 'left' | 'center' | 'right';
  filterable?: boolean;
  sortable?: boolean;
  node?: (label: string) => React.ReactNode;
  srOnly?: boolean;
}

interface CustomControlsProps {
  data: MobileApiPageEnvelope<any>;
  hideSizeControl?: boolean;
  limit: number;
  onLimitChanged: (limit: number) => void;
  onFirstPage: () => void;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onLastPage: () => void;
  customControls?: any;
  // Custom Limits here are [low, medium, high]
  customLimits?: [number, number, number];
}

interface ExternalProps {
  defaultValue?: string; // Autofill text in search bar, does NOT affect filtering on render, see SW-14099
  pagerId: SearchPagerId;
  options: SearchPagerOptions;
  apiRequest?: ApiRequest;
  children: ReactNode;
  customSearchBar?: (props: SearchBarProps) => JSX.Element;
  customControls?: (props: CustomControlsProps) => JSX.Element;
  customLimits?: [number, number, number];
  disablePagerSaving?: boolean; // Pager will not restore state when it is destroyed (navigation, refresh, etc)
  headers?: Array<TableContainerHeader>;
  actionLink?: string;
  actionLabel?: string;
  actionIcon?: React.ReactNode;
  actionId?: string;
  showActionButton?: boolean;
  additionalActions?: React.ReactNode;
  filterContent?: React.ReactNode;
}

export type SearchPagerComponentProps = ExternalProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const mapStateToProps = (
  state: RootState,
  ownProps: ExternalProps & RouteComponentProps
) => ({
  session: state.session,
  pagerState: getPager<any>(state, ownProps.pagerId),
  pathname: ownProps.location.pathname,
});

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      searchPagerCreate,
      searchPagerDestroy,
      searchPagerSetLimit,
      searchPagerFirstPage,
      searchPagerPreviousPage,
      searchPagerNextPage,
      searchPagerLastPage,
      searchPagerChangeQueryDebounced,
      searchPagerChangeFilters,
    },
    dispatch
  );

export const SearchPagerContainer = compose<ComponentClass<ExternalProps>>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SearchPager);

// This is to not break any existing imports after splitting ActionsCell into its own file
export { ActionsCell };
