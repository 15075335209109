import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { Action } from 'redux';
import { COLORS } from 'singlewire-components';
import styled, { createGlobalStyle } from 'styled-components';
import { FooterContainer } from '../layout/FooterContainer';
import { HeaderContainer } from '../layout/HeaderContainer';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: ${COLORS.GRAY_10};
  overflow-x: hidden;
  transition: 0.5s;
`;

const OverlayContent = styled.div`
  margin-top: 100px;
`;

const HiddenBodyScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const OverlayWrapper = ({ children }: PropsWithChildren<{}>) => (
  <Wrapper>
    <HiddenBodyScroll />
    <HeaderContainer isOverlay={true} />
    <OverlayContent id="overlayContent" className="container">
      {children}
    </OverlayContent>
    <FooterContainer />
  </Wrapper>
);

export interface IOverlayContext<T = any> {
  index?: string;
  route?: string;
  parentPageTitle?: string;
  backRoute?: string;
  backRouteLabel?: string;
  onCancel?: () => void;
  onCreate?: (resource: T) => null | undefined | Action[];
  onUpdate?: (resource: T) => null | undefined | Action[];
  showLoadingIndicator?: boolean;
}

const OverlayContext = createContext<IOverlayContext | null>(null);

export const Overlay = ({
  children,
  parentPageTitle,
  backRoute,
  backRouteLabel,
  onCancel,
  onCreate,
  onUpdate,
}: PropsWithChildren<IOverlayContext>) => {
  const value = useMemo(
    () => ({ parentPageTitle, backRouteLabel, backRoute, onCancel, onCreate, onUpdate }),
    [parentPageTitle, backRouteLabel, backRoute, onCancel, onCreate, onUpdate]
  );

  return (
    <OverlayContext.Provider value={value}>
      <OverlayWrapper>{children}</OverlayWrapper>
    </OverlayContext.Provider>
  );
};

export const useOverlay = () => useContext<IOverlayContext | null>(OverlayContext);
