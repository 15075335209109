// A container for all the content we display in the footer.

import { RootState } from '../../core/RootReducer';
import { connect } from 'react-redux';
import { Footer } from './Footer';
import { isFeatureEnabled } from '../../core/utils/session';

export type FooterProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: RootState) => ({
  isFusionMode: isFeatureEnabled('fusion', state.session),
});

export const FooterContainer = connect(mapStateToProps)(Footer);
