import { ActionType, getType } from 'typesafe-actions';
import * as aoiMultiValueLabelActions from './AOIMultiValueLabelAction';
import { MobileApiAreaOfInterest } from '../../mobile-api-types';

export type AOIMultiValueLabelAction = ActionType<typeof aoiMultiValueLabelActions>;

export interface AOIMultiValueLabelState {
  previewAOI?: MobileApiAreaOfInterest;
}

export const initialState: AOIMultiValueLabelState = {};

export default function aoiMultiValueLabelReducer(
  state: AOIMultiValueLabelState = initialState,
  action: AOIMultiValueLabelAction
): AOIMultiValueLabelState {
  switch (action.type) {
    case getType(aoiMultiValueLabelActions.showAOIPreview):
      return { ...state, previewAOI: action.payload.aoi };
    case getType(aoiMultiValueLabelActions.clearPreviewAOI): {
      const { previewAOI, ...newState } = state;
      return newState;
    }

    default:
      return state;
  }
}
