import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { timer } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  MobileApiPageEnvelope,
  MobileApiTrackingEvent,
  MobileApiTrackingEventActivity,
} from '../../mobile-api-types';
import { RootState } from '../RootReducer';
import { resolveRouteUpdateData } from '../route/RouteActions';
import { logout } from '../session/SessionActions';
import store from '../store';
import { BackgroundRef, CancellableRef, req, reqAll } from '../utils/api';
import { isBrowserVisible } from '../utils/common';
import { IgnoreSessionExtendTransform } from '../utils/http-transformers';
import {
  boundsChanged,
  centerMapAroundEvent,
  centerMapAroundUser,
  getAllActivities,
  getTrackingEvents,
  refreshTrackingEvent,
  setActiveTrackingEvents,
  setActivities,
  setTrackingEvents,
  startMonitoringTrackingEvents,
} from './TrackingEventsActions';
import { getMarkerBounds } from './TrackingEventsReducer';

/**
 * gets data on all tracking events for list page
 */
export const getTrackingEventsEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(getTrackingEvents)),
    switchMap(action => {
      const { completed, options } = action.payload;
      const { requestParams, responseFilterPred } = options || {};
      return reqAll<MobileApiTrackingEvent>(
        [
          'trackingEvents',
          [],
          'GET',
          {
            params: {
              includeSummary: true,
              includeInitiatingResource: true,
              ...(requestParams || {}),
            },
          },
        ],
        store,
        BackgroundRef
      ).pipe(
        switchMap(events => {
          const filtered = events.filter(
            responseFilterPred || (event => completed || !event.completedAt)
          );
          return [setTrackingEvents(filtered)];
        }),
        catchError(() => [])
      );
    })
  );

// Periodically check for active tracking events every 30s starting immediately
export const activeTrackingEventPollingEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(startMonitoringTrackingEvents)),
    switchMap(() =>
      timer(0, 30000).pipe(
        filter(() => isBrowserVisible()),
        switchMap(() =>
          req<MobileApiPageEnvelope<MobileApiTrackingEvent>>(
            [
              'trackingEvents',
              [],
              'GET',
              {
                transforms: [IgnoreSessionExtendTransform],
              },
            ],
            store,
            CancellableRef
          ).pipe(
            map(response => {
              return setActiveTrackingEvents(response.data.data.filter(e => e.state === 'active'));
            }),
            catchError(() => [])
          )
        ),
        takeUntil(action$.pipe(ofType(logout)))
      )
    )
  );

export const getTrackingEventActivitiesEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(getAllActivities)),
    switchMap(action =>
      reqAll<MobileApiTrackingEventActivity>(
        ['userTrackingEventActivities', [action.payload.userId, action.payload.eventId], 'GET'],
        store,
        BackgroundRef
      ).pipe(
        switchMap(activities => [setActivities(activities)]),
        catchError(() => [])
      )
    )
  );

export const centerMapAroundEventEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(centerMapAroundEvent)),
    switchMap(action => [
      boundsChanged(
        action.payload.completed
          ? getMarkerBounds(
              (action.payload.event?.summary || [])?.map(({ lat, lng }: any) => ({ lat, lng }))
            )
          : getMarkerBounds(
              (action.payload.event?.summary || [])
                ?.filter((activity: any) => activity.state !== 'terminated')
                .map(({ lat, lng }: any) => ({ lat, lng }))
            )
      ),
    ])
  );

export const centerMapAroundUserEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(centerMapAroundUser)),
    switchMap(action => [boundsChanged(getMarkerBounds([action.payload.user]))])
  );

export const refreshTrackingEventEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(refreshTrackingEvent)),
    withLatestFrom(state$.pipe(map(state => state.trackingEvents.events))),
    switchMap(([action, events]) =>
      req<MobileApiTrackingEvent>(
        ['trackingEvents', [action.payload.eventId], 'GET', { params: { includeSummary: true } }],
        store,
        BackgroundRef
      ).pipe(
        switchMap(response => {
          const event = response.data;
          const updatedEvents = events.map(e => (e.id === event.id ? event : e));
          return [resolveRouteUpdateData({ event }), setTrackingEvents(updatedEvents)];
        }),
        catchError(() => [])
      )
    )
  );

export default combineEpics(
  getTrackingEventsEpic,
  centerMapAroundEventEpic,
  centerMapAroundUserEpic,
  getTrackingEventActivitiesEpic,
  refreshTrackingEventEpic,
  activeTrackingEventPollingEpic
);
