// This is where we combine all the reducers that are active in the initial application load.

import { connectRouter } from 'connected-react-router';
import { createHashHistory } from 'history';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { StateType } from 'typesafe-actions';
import aoiMultiSelectPreview from './aoi/AOIMultiValueLabelReducer';
import branding from './branding/BrandingReducer';
import callStatus from './callaware/CallStatusReducer';
import cloning from './clone/CloneReducer';
import domains from './domains/DomainsReducer';
import facilities from './facilities/FacilitiesReducer';
import grid from './grid/GridReducer';
import icopServers from './icop-servers/ICOPServersReducer';
import incidentManagement from './incidents/IncidentsReducer';
import initialized from './initialized/InitializedReducer';
import layout from './layout/LayoutReducer';
import license from './license/LicenseReducer';
import login from './login/LoginReducer';
import modal from './modal/ModalReducer';
import msteams from './ms-teams/MSTeamsReducer';
import permissions from './permissions/PermissionsReducer';
import phoneCache from './phone-cache/PhoneCacheReducer';
import reports from './reports/ReportsReducer';
import route from './route/RouteReducer';
import ruleBuilder from './rule-builder/RuleBuilderReducer';
import searchPagers from './search-pager/SearchPagerReducer';
import session from './session/SessionReducer';
import sideBar from './side-bar/SideBarReducer';
import systemHealth from './system-health/SystemHealthReducer';
import tabs from './tabs/TabsReducer';
import trackingEvents from './tracking-events/TrackingEventsReducer';
import tts from './tts/TtsReducer';
import urlShortener from './url-shortener/UrlShortenerReducer';

// hash history (initially for compatibility with AngularJS)
export const hashHistory = createHashHistory({
  hashType: 'slash',
});

// Listen for hash history changes and send stats to Google Analytics
hashHistory.listen((location, action) => {
  if (window.ga) {
    window.ga('set', 'page', location.pathname);
    window.ga('send', 'pageview');
  }
});

// These are only the core reducers, not lazily-loaded feature reducers.
export const rootReducers = {
  aoiMultiSelectPreview,
  branding,
  callStatus,
  cloning,
  domains,
  facilities,
  form: formReducer,
  grid,
  icopServers,
  incidentManagement,
  initialized,
  layout,
  license,
  login,
  modal,
  msteams,
  permissions,
  phoneCache,
  reports,
  route,
  router: connectRouter(hashHistory),
  ruleBuilder,
  searchPagers,
  session,
  sideBar,
  systemHealth,
  tabs,
  trackingEvents,
  tts,
  urlShortener,
};

const rootReducer = combineReducers(rootReducers);

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
