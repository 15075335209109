import { Component } from 'react';
import { Icon, Table, TableCell, TableRow } from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../../CommonLocale';
import { MobileApiSyncState } from '../../../mobile-api-types';
import { InformationRegistryModalContainer } from '../InformationRegistryModalContainer';
import { shared_t } from '../SharedLocale';
import { Button } from './Button';
import { FusionSyncStateProps } from './FusionSyncStateContainer';

const StyledTableCell = styled(TableCell)`
  max-width: 200px;
`;

export const SYNC_STATE_MODAL_NAME = 'FusionSyncState';

export type OverallSyncState = 'failed' | 'complete' | 'validation-failed' | 'unknown' | 'pending';

export const getOverallState = (syncStates?: MobileApiSyncState[] | null): OverallSyncState => {
  if (syncStates && syncStates.length > 0) {
    const statuses = syncStates.map(({ status }) => status);
    if (statuses.some(state => state === 'failed')) {
      return 'failed';
    } else if (statuses.some(state => state === 'validation-failed')) {
      return 'validation-failed';
    } else if (
      statuses.some(state => state === 'pending' || state === 'unknown' || state === null)
    ) {
      return 'pending';
    } else {
      return 'complete';
    }
  } else {
    return 'unknown';
  }
};

const getIcon = (syncStates: MobileApiSyncState[]) => {
  switch (getOverallState(syncStates)) {
    case 'complete':
      return <Icon.Success color="success" size="sm" />;
    case 'pending':
      return <Icon.Pending color="warning" size="sm" />;
    case 'validation-failed':
      return <Icon.ValidationFailed color="error" size="sm" />;
    case 'failed':
      return <Icon.Failed color="error" size="sm" />;
    default:
      return <Icon.Success color="success" size="sm" />;
  }
};

const getTooltip = (syncStates: MobileApiSyncState[]) => {
  switch (getOverallState(syncStates)) {
    case 'complete':
      return shared_t(['syncProgress', 'success']);
    case 'pending':
      return `${shared_t(['syncProgress', 'inProgress'])}${
        syncStates.length > 1 ? shared_t(['syncProgress', 'clickToViewDetails']) : ''
      }`;
    case 'failed':
    case 'validation-failed':
      return `${shared_t(['syncProgress', 'failed'])} ${shared_t([
        'syncProgress',
        'clickToViewDetails',
      ])}`;
    default:
      return null;
  }
};

const syncStateValue = (syncState: MobileApiSyncState) => {
  if (syncState.status === 'complete') {
    return <span>{shared_t(['syncProgress', 'successValue'])}</span>;
  } else if (syncState.status === 'pending' || syncState.status === null) {
    return <span>{shared_t(['syncProgress', 'pendingValue'])}</span>;
  } else {
    return (syncState.errors?.length || 0) > 0 ? (
      syncState.errors?.map((error, idx) => <span key={idx}>{error.message}</span>)
    ) : (
      <span>{shared_t(['syncProgress', 'failedValue'])}</span>
    );
  }
};

export class FusionSyncState extends Component<FusionSyncStateProps> {
  // Only update if the state has changed
  shouldComponentUpdate(
    nextProps: Readonly<FusionSyncStateProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return getOverallState(this.props.syncStates) !== 'complete';
  }

  render() {
    const { resourceName, resourceId, syncStates, showModal } = this.props;
    return (
      <>
        <Button
          id={`sync-state-${resourceId}`}
          label={getTooltip(syncStates) || ''}
          startIcon={getIcon(syncStates)}
          size="sm"
          color="gray"
          variant="text"
          onClick={() => showModal({ id: `${SYNC_STATE_MODAL_NAME}_${resourceId}` })}
        />

        <InformationRegistryModalContainer
          title={
            resourceName
              ? shared_t(['syncProgress', 'fullTitle'], { resourceName } as any)
              : shared_t(['syncProgress', 'title'])
          }
          id={`${SYNC_STATE_MODAL_NAME}_${resourceId}`}>
          <Table
            hideSearch
            headers={[
              { label: common_t(['label', 'name']), attribute: 'name' },
              { label: common_t(['label', 'message']), attribute: 'message' },
            ]}>
            {syncStates.map((syncState: MobileApiSyncState, idx) => (
              <TableRow key={`${syncState.id}_${idx}`}>
                <TableCell>{syncState.name}</TableCell>
                <StyledTableCell>{syncStateValue(syncState)}</StyledTableCell>
              </TableRow>
            ))}
          </Table>
        </InformationRegistryModalContainer>
      </>
    );
  }
}
