import moment from 'moment';
import { PureComponent } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { ApiRequest } from '../../common-types';
import {
  DEVICE_REGISTRATIONS_REPORT_API_ID,
  FUSION_SERVER_UPGRADE_FEATURE,
  FUSION_UPGRADE_STATUS_REPORT_API_ID,
  FusionDeltaSyncErrorEventAlarmID,
  GENERAL_COUNTS_REPORT_API_ID,
  ICOP_EXTENSION_ID,
  LicenseAlarmID,
  RedStateAlarmID,
  ScenarioDeviceResolutionFailedAlarmID,
  PotentialSpeakerConfigurationIssueAlarmID,
  SISLoaderAlarmID,
  SpeakerAlarmID,
  SYSTEM_ALARMS_FEATURE,
  TokenExpirationAlarmID,
  YellowStateAlarmID,
} from '../../constants';
import Routes from '../../core/route/Routes';
import { isFeatureSupported, isFeatureSupportedForServer } from '../../core/utils/icop-server';
import { MobileApiAlarm } from '../../mobile-api-types';
import { Alert } from '../shared/components/Alert';
import { Anchor, Link } from '../shared/components/Link';
import { PermissionCheckContainer } from '../shared/PermissionCheckContainer';
import { ResolveDataSafely } from '../shared/ResolveDataSafely';
import { layout_t } from './LayoutLocale';
import { WarningBannerProps } from './WarningBannerContainer';

const AlertContainer = styled.div`
  & > * {
    margin-bottom: 1rem;
  }
`;

export const READ_COUNTS_REPORT: ApiRequest = ['reports', [GENERAL_COUNTS_REPORT_API_ID], 'GET'];
export const DEVICE_COUNTS_REPORT: ApiRequest = [
  'reports',
  [DEVICE_REGISTRATIONS_REPORT_API_ID],
  'GET',
];
export const VIEW_ENDPOINTS: ApiRequest = ['extensionsICOPEndpoints', [], 'GET'];
export const VIEW_SPEAKERS: ApiRequest = ['ipSpeakers', [], 'GET'];
export const VIEW_LOAD_DEFINITIONS: ApiRequest = ['loadDefinitions', [], 'GET'];

export class WarningBanner extends PureComponent<WarningBannerProps> {
  componentDidMount() {
    if (
      this.props.servers?.find(
        server => !isFeatureSupportedForServer(SYSTEM_ALARMS_FEATURE, server)
      )
    ) {
      this.props.startMonitoringApplicationHealth();
    }
    this.props.startMonitoringLiveCallAwareCalls();
    this.props.startMonitoringSystemHealthAlarms();
    this.props.startMonitoringIncidents();
    this.props.startMonitoringTrackingEvents();
    this.props.checkPermission(READ_COUNTS_REPORT);
    this.props.checkPermission(DEVICE_COUNTS_REPORT);
    this.props.checkPermission(VIEW_ENDPOINTS);
    this.props.checkPermission(VIEW_LOAD_DEFINITIONS);
  }

  render() {
    const {
      systemAlarmsSupported,
      confirmFusionServerChange,
      servers,
      activeAlarms,
      ongoingIncidentsState,
      liveCallAwareCalls,
      navigateTo,
      licenseExpiration,
      sisEnabled,
      isFusion,
      viewIcopServers,
      isReverseSyncingEnabled,
      readGeneralCountsReport,
      readDeviceCountsReport,
      bulkSpeakerManagementEnabled,
      activeTrackingEvents,
      shouldShowBulkSpeakerManagementWarning,
      shouldShowDialcastManagementWarning,
    } = this.props;
    const { ongoingIncidentsCount, ongoingIncidents } = ongoingIncidentsState;
    const incidentsAreOngoing = ongoingIncidentsCount > 0;
    const areMultipleIncidents = ongoingIncidentsCount > 1;
    const redAlarm = activeAlarms?.filter(
      (alarm: MobileApiAlarm) => alarm.id === RedStateAlarmID
    )[0];
    const yellowAlarm = activeAlarms?.filter(
      (alarm: MobileApiAlarm) => alarm.id === YellowStateAlarmID
    )[0];
    const licenseAlarm = activeAlarms?.filter(
      (alarm: MobileApiAlarm) => alarm.id === LicenseAlarmID
    )[0];
    const speakerAlarm = activeAlarms?.filter(
      (alarm: MobileApiAlarm) => alarm.id === SpeakerAlarmID
    )[0];
    const sisAlarm = activeAlarms?.filter(
      (alarm: MobileApiAlarm) => alarm.id === SISLoaderAlarmID
    )[0];
    const tokenExpirationAlarm = activeAlarms?.filter(
      (alarm: MobileApiAlarm) => alarm.id === TokenExpirationAlarmID
    )[0];
    const fusionSyncStateAlarm = activeAlarms?.filter(
      (alarm: MobileApiAlarm) => alarm.id === FusionDeltaSyncErrorEventAlarmID
    )[0];
    const scenarioDeviceResolutionFailedAlarm = activeAlarms?.filter(
      (alarm: MobileApiAlarm) => alarm.id === ScenarioDeviceResolutionFailedAlarmID
    )[0];
    const potentialSpeakerConfigurationIssueAlarm = activeAlarms?.filter(
      (alarm: MobileApiAlarm) => alarm.id === PotentialSpeakerConfigurationIssueAlarmID
    )[0];

    const formatExpiration = moment(licenseExpiration).format('M-D-YY');
    const inGracePeriod = moment(licenseExpiration).isBefore(moment());
    const licenseExpires30 = moment(licenseExpiration).subtract(30, 'days').isBefore(moment());
    const canViewServers = servers?.length > 0 && isFusion && viewIcopServers;
    const serversVerisions =
      (servers?.length > 1 && servers.map(server => server.buildInfo?.version)) || [];
    const areServerVersionsMismatched = !serversVerisions.every(
      version => version === serversVerisions[0]
    );

    return (
      <div className="container">
        <AlertContainer>
          {shouldShowBulkSpeakerManagementWarning && (
            <Alert color="warning">
              <Trans i18nKey="layout:warningBanner.fusionServerUpgradeAvailable.bulkSpeakerManagement">
                {/* eslint-disable jsx-a11y/anchor-has-content */}
                <Anchor href="#/fusion-servers" />
                <Anchor target="_blank" href="https://www.singlewire.com/support-tools" />
              </Trans>
            </Alert>
          )}
          {shouldShowDialcastManagementWarning && (
            <Alert color="warning">
              <Trans i18nKey="layout:warningBanner.fusionServerUpgradeAvailable.cloudDialcastManagement">
                {/* eslint-disable jsx-a11y/anchor-has-content */}
                <Anchor href="#/fusion-servers" />
                <Anchor target="_blank" href="https://www.singlewire.com/support-tools" />
              </Trans>
            </Alert>
          )}
          {incidentsAreOngoing && (
            <Alert
              id="ongoingIncidentsWarning"
              color="warning"
              title={layout_t(['warningBanner', 'labels', 'caution'])}
              dismissable={false}
              slim>
              <Trans
                i18nKey={
                  areMultipleIncidents
                    ? 'layout:warningBanner.incidents.ongoingIncidentsMessage'
                    : 'layout:warningBanner.incidents.ongoingIncidentMessage'
                }>
                <Link
                  to={
                    areMultipleIncidents || !ongoingIncidents[0]
                      ? Routes.CommandCenter.Incidents.List
                      : Routes.CommandCenter.Incidents.Details(ongoingIncidents[0].id)
                  }
                />
              </Trans>
            </Alert>
          )}
          {systemAlarmsSupported && (
            <>
              {redAlarm && canViewServers && (
                <Alert
                  id={layout_t(['warningBanner', 'alarms', 'redAlarm'])}
                  color="error"
                  dismissable={false}
                  primaryButton={{
                    label: layout_t(['warningBanner', 'alarms', 'visitSystemHealth']),
                    onClick: () => navigateTo(Routes.SystemHealth.Dashboard),
                  }}
                  secondaryButton={{
                    label: layout_t(['warningBanner', 'alarms', 'switchServers']),
                    onClick: () => navigateTo(Routes.Servers.Index),
                  }}>
                  {layout_t(['warningBanner', 'alarms', 'redAlarmMessage'])}
                </Alert>
              )}
              {yellowAlarm && canViewServers && (
                <Alert
                  id={layout_t(['warningBanner', 'alarms', 'yellowAlarm'])}
                  color="warning"
                  dismissable={false}
                  primaryButton={{
                    label: layout_t(['warningBanner', 'alarms', 'visitSystemHealth']),
                    onClick: () => navigateTo(Routes.SystemHealth.Dashboard),
                  }}
                  secondaryButton={{
                    label: layout_t(['warningBanner', 'alarms', 'switchServers']),
                    onClick: () => navigateTo(Routes.Servers.Index),
                  }}>
                  {layout_t(['warningBanner', 'alarms', 'yellowAlarmMessage'])}
                </Alert>
              )}
            </>
          )}
          {fusionSyncStateAlarm && canViewServers && isReverseSyncingEnabled && (
            <Alert
              id={layout_t(['warningBanner', 'alarms', 'fusionSyncState', 'title'])}
              color="error"
              dismissable={false}
              slim>
              <Trans i18nKey="layout:warningBanner.alarms.fusionSyncState.message">
                <Link to={Routes.SystemHealth.Dashboard} />
              </Trans>
            </Alert>
          )}
          {licenseAlarm && readGeneralCountsReport && (
            <Alert
              id={layout_t(['warningBanner', 'alarms', 'licenseAlarm'])}
              color="error"
              dismissable={false}
              slim>
              <Trans i18nKey="layout:warningBanner.alarms.licenseAlarmMessage">
                <Link to={Routes.SystemHealth.Dashboard} />
              </Trans>
            </Alert>
          )}
          {speakerAlarm && readDeviceCountsReport && servers.length > 0 && (
            <Alert
              id={layout_t(['warningBanner', 'alarms', 'speakersUnregisteredAlarm'])}
              slim
              color="warning"
              dismissable={false}>
              <Trans i18nKey="layout:warningBanner.alarms.speakerAlarmMessage">
                <Link to={Routes.SystemHealth.Dashboard} />
              </Trans>
            </Alert>
          )}
          {sisAlarm && sisEnabled && (
            <Alert
              id={layout_t(['warningBanner', 'alarms', 'sisLoaderAlarm'])}
              slim
              color="warning"
              dismissable={false}>
              <Trans i18nKey="layout:warningBanner.alarms.sisLoaderAlarmMessage">
                <Link to={Routes.SystemHealth.Dashboard} />
              </Trans>
            </Alert>
          )}
          {tokenExpirationAlarm && (
            <Alert
              id={layout_t(['warningBanner', 'alarms', 'tokenExpirationAlarm'])}
              slim
              color="warning"
              dismissable={false}>
              <Trans i18nKey="layout:warningBanner.alarms.tokenExpirationAlarmMessage">
                <Link to={Routes.SystemHealth.Dashboard} />
              </Trans>
            </Alert>
          )}
          {scenarioDeviceResolutionFailedAlarm && (
            <Alert
              id={layout_t(['warningBanner', 'alarms', 'scenarioDeviceResolutionFailedAlarm'])}
              slim
              color="warning"
              dismissable={false}>
              <Trans i18nKey="layout:warningBanner.alarms.scenarioDeviceResolutionFailedAlarmMessage">
                <Link to={Routes.SystemHealth.Dashboard} />
              </Trans>
            </Alert>
          )}
          {potentialSpeakerConfigurationIssueAlarm && bulkSpeakerManagementEnabled && (
            <Alert
              id={layout_t(['warningBanner', 'alarms', 'potentialSpeakerConfigurationIssueAlarm'])}
              slim
              color="warning"
              dismissable={false}>
              <Trans i18nKey="layout:warningBanner.alarms.potentialSpeakerConfigurationIssueAlarmMessage">
                <Link to={Routes.SystemHealth.Dashboard} />
              </Trans>
            </Alert>
          )}
          {activeTrackingEvents.length > 0 && (
            <Alert
              id="tracking-events-alert"
              title={layout_t(['warningBanner', 'labels', 'warning'])}
              color="error"
              slim>
              <Trans
                i18nKey={
                  activeTrackingEvents.length > 1
                    ? 'layout:warningBanner.trackingEvent.eventsMessage'
                    : 'layout:warningBanner.trackingEvent.eventMessage'
                }>
                <Link
                  to={
                    activeTrackingEvents.length > 1
                      ? Routes.TrackingEvents.Index
                      : Routes.TrackingEvents.Event(activeTrackingEvents[0].id)
                  }
                />
              </Trans>
            </Alert>
          )}

          {(inGracePeriod || licenseExpires30) && (
            <Alert
              id="licenseExpiredAlarm"
              slim
              color={inGracePeriod ? 'error' : 'warning'}
              dismissable={false}>
              {inGracePeriod ? (
                <Trans i18nKey="layout:warningBanner.expiration.gracePeriod">
                  {/* eslint-disable jsx-a11y/anchor-has-content */}
                  {formatExpiration}
                  {moment(licenseExpiration).add(30, 'days').format('M-D-YY')}
                  <Anchor href="mailto:renewals@singlewire.com" target="_blank" />
                </Trans>
              ) : (
                <Trans i18nKey="layout:warningBanner.expiration.warning">
                  {/* eslint-disable jsx-a11y/anchor-has-content */}
                  {formatExpiration}
                  <Anchor href="mailto:renewals@singlewire.com" target="_blank" />
                </Trans>
              )}
            </Alert>
          )}
          <PermissionCheckContainer
            permissions={['extensionEndpointActivities', [ICOP_EXTENSION_ID, '*'], 'POST']}>
            <ResolveDataSafely
              resolve={{
                fusionServerUpgradeStatus: [
                  'reports',
                  [FUSION_UPGRADE_STATUS_REPORT_API_ID],
                  'GET',
                ],
              }}
              render={({ fusionServerUpgradeStatus }) =>
                fusionServerUpgradeStatus?.outOfDateEndpoints?.length > 0 && (
                  <Alert id="upgradeServerWarning" dismissable={false} color="warning">
                    <>
                      {isFeatureSupported(FUSION_SERVER_UPGRADE_FEATURE) ? (
                        <Trans i18nKey="layout:warningBanner.fusionServerUpgradeAvailable.warning">
                          {/* eslint-disable jsx-a11y/anchor-has-content */}
                          <Anchor href="#/fusion-servers" />
                          <Anchor target="_blank" href="https://www.singlewire.com/support-tools" />
                        </Trans>
                      ) : (
                        <Trans i18nKey="layout:warningBanner.fusionServerUpgradeAvailable.oldWarning">
                          {/* eslint-disable jsx-a11y/anchor-has-content */}
                          <Anchor target="_blank" href="https://www.singlewire.com/support-tools" />
                        </Trans>
                      )}
                    </>
                  </Alert>
                )
              }
            />
          </PermissionCheckContainer>
          {areServerVersionsMismatched && (
            <Alert id="misMNatchedServers" color="error" dismissable={false} slim>
              <Trans i18nKey="layout:warningBanner.misMatchedServerWarning">
                <Anchor href="#/fusion-servers" />
                <Anchor target="_blank" href="https://www.singlewire.com/support-tools" />{' '}
              </Trans>
            </Alert>
          )}
          {liveCallAwareCalls && liveCallAwareCalls[0] && (
            <Alert id="liveCallAwareWarning" color="warning" dismissable={false}>
              <>
                {layout_t(['warningBanner', 'callAware', 'callActiveMessage'])}
                {Array.from(new Set(liveCallAwareCalls.map(c => c.endpointId))).map(
                  (endpointId: string) => (
                    <Link
                      key={endpointId}
                      onClick={e => {
                        e.preventDefault(); // Need this because linter doesn't like href=javascript:void(0)
                        if (
                          (this.props.selectedICOPServer && this.props.selectedICOPServer.id) !==
                          endpointId
                        ) {
                          confirmFusionServerChange(endpointId);
                        }
                        navigateTo(Routes.CallAware.History.Index);
                      }}
                      to="/#">
                      {servers
                        .filter(server => server.id === endpointId)
                        .map(matchingServer => matchingServer.name)
                        .join(', ')}
                    </Link>
                  )
                )}
              </>
            </Alert>
          )}
        </AlertContainer>
      </div>
    );
  }
}
