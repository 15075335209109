import classNames from 'classnames';
import { PureComponent } from 'react';
import { List } from 'react-bootstrap/lib/Media';
import { Icon } from 'singlewire-components';
import styled from 'styled-components';
import { SidebarType } from '../../core/layout/LayoutActions';
import { ElementLink, ElementMenu } from '../../core/utils/side-bar';
import { ForceReloadLinkContainer } from '../shared/ForceReloadLinkContainer';
import { SinglewireIcon } from '../shared/SinglewireIcon';
import { SideBarNavMenuElementProps } from './SideBarNavMenuElementContainer';

const StyledIcon = styled.span`
  padding-left: 0.3125rem;
  padding-right: 0.46875rem;

  & > img {
    display: inline-block;
    margin-top: -2px;
  }
`;

const StyledForceReloadLink = styled(ForceReloadLinkContainer)`
  &:focus-visible {
    outline: 2px solid white;
    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 2px,
      rgb(0, 90, 138) 0px 0px 0px 4px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
`;

const StyledAnchor = styled.a`
  &:focus-visible {
    outline: 2px solid white;
    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 2px,
      rgb(0, 90, 138) 0px 0px 0px 4px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
`;

export const ImageIcon = ({ src, progress }: { src: string; progress: string }) => (
  <StyledIcon>
    <img id={progress} alt={progress} src={src} width="12px" height="12px" />
  </StyledIcon>
);
function isMenu(element: ElementMenu | ElementLink): element is ElementMenu {
  return !!(element as ElementMenu).children;
}

function isLink(element: ElementMenu | ElementLink): element is ElementLink {
  return !!(element as ElementLink).routerLink;
}

export class SideBarNavMenuElement extends PureComponent<SideBarNavMenuElementProps> {
  expandElement = (element: ElementMenu) => this.props.expandElement(element);

  // We use keypress for this because the a tags with role button don't handle hitting enter key correctly
  handleKeyPress = (element: any) => (event: any) => {
    if (event.key === 'Enter') {
      this.expandElement(element);
    }
  };

  render() {
    const { element, expandElement, closeSidebar } = this.props;
    return (
      <li
        className={classNames('sub-menu-li', {
          'sub-menu': isMenu(element) && element.children,
          toggled: isMenu(element) && element.isExpanded,
        })}>
        {isMenu(element) && (
          <>
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <StyledAnchor
              role="button"
              id={element.id}
              tabIndex={0}
              onClick={() => expandElement(element)}
              aria-expanded={element.isExpanded}
              onKeyPress={this.handleKeyPress(element)}>
              {element.icon && <SinglewireIcon icon={element.icon} />}
              {element.imageSrc && <ImageIcon src={element.imageSrc} progress={element.title} />}
              {element.title}
              <div className="pull-right">
                {!element.isExpanded ? (
                  <Icon.Add size="sm" color="inherit" />
                ) : (
                  <Icon.Remove size="sm" color="inherit" />
                )}
              </div>
            </StyledAnchor>
          </>
        )}
        {isMenu(element) && element.isExpanded && (
          <List>
            {element.children.map((childElement: any) => (
              <SideBarNavMenuElement
                key={childElement.id || childElement.routerLink}
                element={childElement}
                closeSidebar={closeSidebar}
                expandElement={expandElement}
              />
            ))}
          </List>
        )}
        {isLink(element) && (
          <StyledForceReloadLink
            to={element.routerLink}
            tabIndex={0}
            className={classNames({ active: element.isActive })}
            onClick={() => closeSidebar(SidebarType.Left)}>
            {element.icon && <SinglewireIcon icon={element.icon} />}
            {element.imageSrc && <ImageIcon src={element.imageSrc} progress={element.title} />}{' '}
            {element.title}
          </StyledForceReloadLink>
        )}
      </li>
    );
  }
}
