import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ModalProps as RegistryModalProps } from 'singlewire-components';
import { RootState } from '../../core/RootReducer';
import { confirmModal, hideModal } from '../../core/modal/ModalActions';
import { ConfirmationRegistryModal } from './ConfirmationRegistryModal';

interface ExternalProps {
  id: string;
  title: string;
  children: ReactNode;
  registryModalProps?: Partial<RegistryModalProps>;
  confirmCb?: () => Action[];
  confirmTitle?: string;
  textConfirm?: string;
  textConfirmLabel?: string;
  size?: 'sm' | 'md' | 'lg';
  isDestructive?: boolean;
}

export type ConfirmationModalProps = ExternalProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const mapStateToProps = (state: RootState) => ({
  isSaving: !!state.modal.modal && state.modal.modal.isSaving,
});

export const mapDispatchToProps = { hideModal, confirmModal };

export const ConfirmationRegistryModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationRegistryModal);
