import { registerResource } from '../../core/utils/localization';

export const ARIALocale = {
  en: {
    button: {
      edit: 'Edit {{name}} {{resource}}',
      details: 'View {{name}} {{resource}}',
      clear: 'Clear {{name}} {{resource}}',
      delete: 'Delete {{name}} {{resource}}',
      initiate: 'Initiate {{name}} {{resource}}',
      actions: '{{name}} {{resource}} Actions',
      end: 'End {{name}} {{resource}}',
      recipients: 'View Recipients',
    },

    notification: {
      details: 'View notification details for {{name}} sent {{time}}',
      followUp: 'Send follow-up for {{name}} {{resource}}',
      viewResponses: 'View responses for {{name}} {{resource}}',
      viewConferenceCall: 'View conference calls for {{name}} {{resource}}',
    },
  },
};

export default ARIALocale;
export const aria_t = registerResource<typeof ARIALocale.en>('aria', ARIALocale);
