import { useReducer } from 'react';
import { AlertProps, Box, Alert as RegistryAlert } from 'singlewire-components';
import styled from 'styled-components';
import { shared_t } from '../SharedLocale';

const AlertContainer = styled.div`
  flex: 1;
  color: #000000;
`;

const getTitleFromType = (title?: string, type?: string) => {
  switch (type) {
    case 'primary':
      return shared_t(['alert', 'infoDefaultTitle']);
    case 'warning':
      return shared_t(['alert', 'warningDefaultTitle']);
    case 'error':
      return shared_t(['alert', 'errorDefaultTitle']);
    case 'success':
      return shared_t(['alert', 'successDefaultTitle']);
    default:
      return title || shared_t(['alert', 'infoDefaultTitle']);
  }
};

export const Alert = (props: AlertProps) => {
  return (
    <AlertContainer>
      <RegistryAlert
        {...props}
        title={props.title ? props.title : getTitleFromType(props.title, props.color)}
      />
    </AlertContainer>
  );
};

export const DismissableAlert = (props: AlertProps) => {
  const [open, toggleOpen] = useReducer(s => !s, true);
  return open ? (
    <Box mb="md">
      <Alert {...props} dismissable={true} onDismiss={toggleOpen} />
    </Box>
  ) : (
    <></>
  );
};
