import { Location } from 'history';
import { PureComponent, useCallback } from 'react';
import { Prompt, Redirect, Route } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';
import { core_t } from '../core/CoreLocale';
import { BrandingContext, defaultBranding } from '../core/branding/BrandingContext';
import SinglewireFormsContextProvider, {
  FormMeta,
  useSinglewireForms,
} from '../core/forms/SinglewireFormsContext';
import { SidebarType } from '../core/layout/LayoutActions';
import { showDirtyFormOrActiveOperationModal } from '../core/modal/ModalActions';
import { shouldShowDirtyFormOrActiveOperationModal } from '../core/modal/ModalEpics';
import { navigateTo } from '../core/navigation/NavigationActions';
import Routes from '../core/route/Routes';
import { store } from '../core/store';
import { CancellableRef, cancelRequests } from '../core/utils/api';
import { isSelfServiceMode } from '../core/utils/session';
import { AppProps } from './AppContainer';
import { ErrorBoundary } from './ErrorBoundary';
import { ExceptionContainer } from './features/exception/ExceptionContainer';
import { LoginContainer } from './features/login/LoginContainer';
import { FooterContainer } from './layout/FooterContainer';
import { HeaderContainer } from './layout/HeaderContainer';
import { ModalsContainer } from './layout/ModalsContainer';
import { QuickCancelContainer } from './layout/QuickCancelContainer';
import { QuickSendContainer } from './layout/QuickSendContainer';
import { SideBarNavMenuContainer } from './layout/SideBarNavMenuContainer';
import { WarningBannerContainer } from './layout/WarningBannerContainer';
import { asyncWrapper } from './shared/AsyncWrapper';
import { AuthenticatedRoute } from './shared/AuthenticatedRoute';
import { RedirectRouteContainer } from './shared/RedirectRouteContainer';
import { SafeSwitch } from './shared/SafeSwitch';

import 'singlewire-components/dist/index.css';
import { AccessDeniedContainer } from './features/access-denied/AccessDeniedContainer';

const AsyncActivationGroups = asyncWrapper(() => import('./features/activation-groups'));
const AsyncAdmin = asyncWrapper(() => import('./features/admin'));
const AsyncAOIs = asyncWrapper(() => import('./features/areas-of-interest'));
const AsyncApiDevices = asyncWrapper(() => import('./features/api-devices'));
const AsyncBells = asyncWrapper(() => import('./features/bells'));
const AsyncFusionConfiguration = asyncWrapper(
  () => import('./features/icop/admin/fusion-configuration')
);
const AsyncBroadcastParameters = asyncWrapper(
  () => import('./features/icop/admin/network-config/broadcast-parameters')
);
const AsyncNetworkConfig = asyncWrapper(() => import('./features/icop/admin/network-config'));
const AsyncSlpParametersConfig = asyncWrapper(() => import('./features/icop/admin/slp-parameters'));
const AsyncSpeakerParameters = asyncWrapper(() => import('./features/icop/admin/speaker-settings'));
const AsyncCallHistory = asyncWrapper(() => import('./features/callaware'));
const AsyncCiscoWebexTeams = asyncWrapper(() => import('./features/cisco-webex-teams'));
const AsyncCommandCenter = asyncWrapper(() => import('./features/command-center'));
const AsyncApiConnectors = asyncWrapper(() => import('./features/rule-actions'));
const AsyncIpCameras = asyncWrapper(() => import('./features/ip-camera'));
const AsyncConferenceCall = asyncWrapper(() => import('./features/conference-call'));
const AsyncConfirmationRequests = asyncWrapper(() => import('./features/confirmation-requests'));
const AsyncDeviceGroups = asyncWrapper(() => import('./features/device-groups'));
const AsyncDistributionLists = asyncWrapper(() => import('./features/distribution-lists'));
const AsyncDomains = asyncWrapper(() => import('./features/domains'));
const AsyncDomainsConfig = asyncWrapper(() => import('./features/domains-config'));
const AsyncFusionFailover = asyncWrapper(() => import('./features/fusion-failover'));
const AsyncGMSDevices = asyncWrapper(() => import('./features/generic-multicast-streaming'));
const AsyncHome = asyncWrapper(() => import('./features/home'));
const AsyncIDNSettings = asyncWrapper(() => import('./features/idn'));
const AsyncInboundCAP = asyncWrapper(() => import('./features/inbound-cap'));
const AsyncIPAWSAlerts = asyncWrapper(() => import('./features/ipaws-alerts'));
const AsyncIPAWSAreas = asyncWrapper(() => import('./features/ipaws-areas'));
const AsyncIPAWSConfig = asyncWrapper(() => import('./features/ipaws-config'));
const AsyncMessageTemplates = asyncWrapper(() => import('./features/message-templates'));
const AsyncNotificationProfiles = asyncWrapper(() => import('./features/notification-profiles'));
const AsyncNotifications = asyncWrapper(() => import('./features/notifications'));
const AsyncOutboundCap = asyncWrapper(() => import('./features/outbound-cap'));
const AsyncOutboundRss = asyncWrapper(() => import('./features/outbound-rss'));
const AsyncSecurityGroups = asyncWrapper(() => import('./features/security-groups'));
const AsyncServers = asyncWrapper(() => import('./features/servers'));
const AsyncTeamsGroup = asyncWrapper(() => import('./features/ms-teams-collab-group'));
const AsyncTriggers = asyncWrapper(() => import('./features/triggers'));
const AsyncTtsConfig = asyncWrapper(() => import('./features/tts-config'));
const AsyncTwitter = asyncWrapper(() => import('./features/twitter'));
const AsyncUserLoaders = asyncWrapper(() => import('./features/user-loaders'));
const AsyncSISLoaders = asyncWrapper(() => import('./features/sis-loaders'));
const AsyncBulkUpload = asyncWrapper(() => import('./features/bulk-upload'));
const AsyncUsers = asyncWrapper(() => import('./features/users'));
const AsyncCTICertificates = asyncWrapper(() => import('./features/cti-certificates'));
const AsyncM2M = asyncWrapper(() => import('./features/m2m'));
const AsyncInboundRSS = asyncWrapper(() => import('./features/inbound-rss'));
const AsyncIPSpeakers = asyncWrapper(() => import('./features/ip-speakers'));
const AsyncIPSpeakersV2 = asyncWrapper(() => import('./features/ip-speakers-v2'));
const AsyncDesktopNotifiers = asyncWrapper(() => import('./features/desktop-notifiers'));
const AsyncInboundEmail = asyncWrapper(() => import('./features/inbound-email'));
const AsyncDialCast = asyncWrapper(() => import('./features/dialcast'));
const AsyncDialCastV2 = asyncWrapper(() => import('./features/dialcast-v2'));
const AsyncScheduledNotifications = asyncWrapper(
  () => import('./features/scheduled-notifications')
);
const AsyncDesktopUsers = asyncWrapper(() => import('./features/desktops'));
const AsyncLPI = asyncWrapper(() => import('./features/legacy-paging-interface'));
const AsyncNightBell = asyncWrapper(() => import('./features/nightbell'));
const AsyncMobileSelfRegistration = asyncWrapper(() => import('./features/self-registration'));
const AsyncMobileHelp = asyncWrapper(() => import('./features/help'));
const AsyncMobileAppearance = asyncWrapper(() => import('./features/appearance'));
const AsyncQuickURL = asyncWrapper(() => import('./features/quick-url'));
const AsyncDoNotDisturb = asyncWrapper(() => import('./features/do-not-disturb'));
const AsyncSMS = asyncWrapper(() => import('./features/sms'));
const AsyncInformaCastApps = asyncWrapper(() => import('./features/informacast-apps'));
const AsyncSettings = asyncWrapper(() => import('./features/settings'));
const AsyncKontaktioWearablePanicButton = asyncWrapper(
  () => import('./features/kontaktio-wearable-panic-button')
);
const AsyncApplications = asyncWrapper(() => import('./features/applications'));
const AsyncPublicApplications = asyncWrapper(() => import('./features/public-applications'));
const AsyncCalling = asyncWrapper(() => import('./features/calling'));
const AsyncMSTeamsConfig = asyncWrapper(() => import('./features/ms-teams-config'));
const AsyncWebexCallingConfig = asyncWrapper(() => import('./features/webex-calling-config'));
const AsyncEmail = asyncWrapper(() => import('./features/email'));
const AsyncDialToIntercom = asyncWrapper(() => import('./features/dial-to-intercom'));
const AsyncVoiceMenus = asyncWrapper(() => import('./features/voice-menus'));
const AsyncPagingGateway = asyncWrapper(() => import('./features/paging-gateway'));
const AsyncReports = asyncWrapper(() => import('./features/reports'));
const AsyncTrackingEvents = asyncWrapper(() => import('./features/tracking-events'));
const AsyncTrackingEventNotifications = asyncWrapper(
  () => import('./features/tracking-event-notifications')
);
const AsyncCucmClusters = asyncWrapper(() => import('./features/cucm-clusters'));
const AsyncZoom = asyncWrapper(() => import('./features/zoom'));
const AsyncSystemHealth = asyncWrapper(() => import('./features/system-health'));

const RedirectToHome = () => <Redirect to={Routes.Home} />;
const RedirectToBells = () => <Redirect to={Routes.Bells.Overview} />;
const RedirectToActivationGroups = () => <Redirect to={Routes.ActivationGroups.Index} />;

// The Prompt component accepts a function as `message` prop.
const dirtyFormPrompt = (forms: Record<string, FormMeta>, location: Location<any>) => {
  const formIdsToSkip = Object.entries(forms).reduce((acc, [formId, formMeta]) => {
    if (formMeta?.skipDirtyFormChecking?.(location)) {
      return [...acc, formId];
    } else {
      return acc;
    }
  }, [] as string[]);
  // need to do this check here instead of as `when` prop, otherwise location change will not be prevented
  if (shouldShowDirtyFormOrActiveOperationModal(formIdsToSkip)) {
    store.dispatch(
      showDirtyFormOrActiveOperationModal(
        core_t(['navigationWarningConfirmation', 'title']),
        core_t(['navigationWarningConfirmation', 'message']),
        formIdsToSkip,
        () => [navigateTo(location.pathname + location.search)]
      )
    );
    // Always prevent navigation when there is a dirty form/confirmable req, only change location when user confirms.
    return false;
  } else {
    cancelRequests(CancellableRef);
    return true;
  }
};

const DirtyFormPrompt = () => {
  const { forms } = useSinglewireForms();
  const message = useCallback((location: Location) => dirtyFormPrompt(forms, location), [forms]);
  return <Prompt when={true} message={message} />;
};

export class App extends PureComponent<AppProps> {
  componentDidMount() {
    this.props.initialize();
    this.props.initializePendo();
  }

  render() {
    const {
      isLoggedIn,
      initialized,
      primaryColor,
      secondaryColor,
      logoPath,
      logoAltText,
      customHelpUrl,
      ipCamerasEnabled,
      webexCallingEnabled,
    } = this.props;

    const resolvedLogoPath = logoPath ?? defaultBranding.logoPath;
    const resolvedLogoAltText = logoAltText ?? defaultBranding.logoAltText;

    return (
      <BrandingContext.Provider
        value={{
          primaryColor: primaryColor ?? defaultBranding.primaryColor,
          secondaryColor: secondaryColor ?? defaultBranding.secondaryColor,
          logoPath: resolvedLogoPath,
          logoAltText: resolvedLogoAltText,
          customHelpUrl,
        }}>
        <>
          {initialized && isLoggedIn && <HeaderContainer />}
          {!isLoggedIn && (
            <img src={resolvedLogoPath} alt={resolvedLogoAltText} className="logged-out-logo" />
          )}
          <main id="main" role="main">
            {initialized && isLoggedIn && <ModalsContainer />}

            {initialized && isLoggedIn && !isSelfServiceMode() && <SideBarNavMenuContainer />}

            {initialized && isLoggedIn && !isSelfServiceMode() && <QuickSendContainer />}

            {initialized && isLoggedIn && !isSelfServiceMode() && <QuickCancelContainer />}

            <section id="content" onClick={this.closeLeftSidebar}>
              {initialized && isLoggedIn && !isSelfServiceMode() && <WarningBannerContainer />}

              <div className="container">
                <ToastContainer transition={Flip} pauseOnFocusLoss={false} pauseOnHover={true} />

                {/*This is the React router*/}
                {initialized && (
                  <SinglewireFormsContextProvider>
                    <Route
                      render={props => (
                        <ErrorBoundary currentPath={props.location.pathname}>
                          <DirtyFormPrompt />
                          <SafeSwitch>
                            <Route path="/" exact={true} component={RedirectToHome} />
                            <AuthenticatedRoute path={Routes.Home} component={AsyncHome} />
                            <Route path={Routes.Exception} component={ExceptionContainer} />
                            <Route path={Routes.Login} component={LoginContainer} />
                            <AuthenticatedRoute
                              path={Routes.AccessDenied}
                              component={AccessDeniedContainer}
                            />
                            <AuthenticatedRoute
                              path={Routes.ActivationGroups.Index}
                              component={AsyncActivationGroups}
                            />
                            <AuthenticatedRoute
                              path={Routes.LegacyActivationGroups.Index}
                              component={RedirectToActivationGroups}
                            />
                            <AuthenticatedRoute
                              path={Routes.DistributionLists.Index}
                              component={AsyncDistributionLists}
                            />
                            <AuthenticatedRoute
                              path={Routes.CallAware.Index}
                              component={AsyncCallHistory}
                            />
                            <AuthenticatedRoute
                              path={Routes.SecurityGroups.Index}
                              component={AsyncSecurityGroups}
                            />
                            <AuthenticatedRoute
                              path={Routes.Servers.Index}
                              component={AsyncServers}
                            />
                            <AuthenticatedRoute
                              path={Routes.Triggers.Index}
                              component={AsyncTriggers}
                            />
                            <AuthenticatedRoute
                              path={Routes.TtsConfig}
                              component={AsyncTtsConfig}
                            />
                            <AuthenticatedRoute
                              path={Routes.UserLoaders.Index}
                              component={AsyncUserLoaders}
                            />
                            <AuthenticatedRoute
                              path={Routes.SISLoaders.Index}
                              component={AsyncSISLoaders}
                            />
                            <AuthenticatedRoute
                              path={Routes.BulkUpload.Index}
                              component={AsyncBulkUpload}
                            />
                            <AuthenticatedRoute
                              path={Routes.SelfRegistration}
                              component={AsyncMobileSelfRegistration}
                            />
                            <AuthenticatedRoute path={Routes.Help} component={AsyncMobileHelp} />
                            <AuthenticatedRoute
                              path={Routes.Appearance}
                              component={AsyncMobileAppearance}
                            />
                            <AuthenticatedRoute path={Routes.Users.Index} component={AsyncUsers} />
                            <AuthenticatedRoute
                              path={Routes.Devices.Index}
                              component={AsyncApiDevices}
                            />
                            <AuthenticatedRoute path={Routes.Admin.Index} component={AsyncAdmin} />
                            <AuthenticatedRoute
                              path={Routes.AnonymousUsers.Index}
                              component={AsyncUsers}
                            />
                            <AuthenticatedRoute
                              path={Routes.ServiceAccounts.Index}
                              component={AsyncUsers}
                            />
                            <AuthenticatedRoute
                              path={Routes.IDNSettings.Index}
                              component={AsyncIDNSettings}
                            />
                            <AuthenticatedRoute
                              path={Routes.DomainsConfig}
                              exact={true}
                              component={AsyncDomainsConfig}
                            />
                            <AuthenticatedRoute
                              path={Routes.Domains.Index}
                              component={AsyncDomains}
                            />
                            <AuthenticatedRoute
                              path={Routes.FusionConfiguration.Index}
                              component={AsyncFusionConfiguration}
                            />
                            <AuthenticatedRoute
                              path={Routes.AreasOfInterest.Index}
                              component={AsyncAOIs}
                            />
                            <AuthenticatedRoute
                              path={Routes.CommandCenter.Index}
                              component={AsyncCommandCenter}
                            />
                            <AuthenticatedRoute
                              path={Routes.ApiConnectors.Index}
                              component={AsyncApiConnectors}
                            />
                            {ipCamerasEnabled && (
                              <AuthenticatedRoute
                                path={Routes.IpCameras.Index}
                                component={AsyncIpCameras}
                              />
                            )}
                            <AuthenticatedRoute
                              path={Routes.DialCast.Index}
                              component={AsyncDialCast}
                            />
                            <AuthenticatedRoute
                              path={Routes.DialCastV2.Index}
                              component={AsyncDialCastV2}
                            />
                            <AuthenticatedRoute
                              path={Routes.InboundCap.Index}
                              component={AsyncInboundCAP}
                            />
                            <AuthenticatedRoute
                              path={Routes.IPAWSAlerts.Index}
                              component={AsyncIPAWSAlerts}
                            />
                            <AuthenticatedRoute
                              path={Routes.IPAWSConfig.Index}
                              component={AsyncIPAWSConfig}
                            />
                            <AuthenticatedRoute
                              path={Routes.IPAWSAreas.Index}
                              component={AsyncIPAWSAreas}
                            />
                            <AuthenticatedRoute
                              path={Routes.GenericMulticastStreaming.Index}
                              component={AsyncGMSDevices}
                            />
                            <AuthenticatedRoute
                              path={Routes.MessageTemplates.List}
                              component={AsyncMessageTemplates}
                            />
                            <AuthenticatedRoute
                              path={Routes.Notifications.List}
                              component={AsyncNotifications}
                            />
                            <AuthenticatedRoute path={Routes.Bells.Index} component={AsyncBells} />
                            <AuthenticatedRoute
                              path={Routes.LegacyBells.Index}
                              component={RedirectToBells}
                            />
                            <AuthenticatedRoute
                              path={Routes.FollowUpMessageTemplate.Index}
                              component={AsyncMessageTemplates}
                            />

                            <AuthenticatedRoute
                              path={Routes.OutboundRss.Index}
                              component={AsyncOutboundRss}
                            />
                            <AuthenticatedRoute
                              path={Routes.TeamsGroup.Index}
                              component={AsyncTeamsGroup}
                            />
                            <AuthenticatedRoute
                              path={Routes.CiscoWebexTeams.Index}
                              component={AsyncCiscoWebexTeams}
                            />
                            <AuthenticatedRoute
                              path={Routes.FusionFailover.Index}
                              component={AsyncFusionFailover}
                            />
                            <AuthenticatedRoute
                              path={Routes.Twitter.Index}
                              component={AsyncTwitter}
                            />
                            <AuthenticatedRoute
                              path={Routes.ConferenceCall.Index}
                              component={AsyncConferenceCall}
                            />
                            <AuthenticatedRoute
                              path={Routes.OutboundCap.Index}
                              component={AsyncOutboundCap}
                            />
                            <AuthenticatedRoute
                              path={Routes.NotificationProfiles.Index}
                              component={AsyncNotificationProfiles}
                            />
                            <AuthenticatedRoute
                              path={Routes.ConfirmationRequests.Index}
                              component={AsyncConfirmationRequests}
                            />
                            <AuthenticatedRoute
                              path={Routes.DeviceGroups.Index}
                              component={AsyncDeviceGroups}
                            />
                            <AuthenticatedRoute
                              path={Routes.CTICertificates.Index}
                              component={AsyncCTICertificates}
                            />
                            <AuthenticatedRoute path={Routes.M2M.Index} component={AsyncM2M} />
                            <AuthenticatedRoute
                              component={AsyncInboundRSS}
                              path={Routes.InboundRSS.Index}
                            />
                            <AuthenticatedRoute
                              component={AsyncInboundEmail}
                              path={Routes.InboundEmail.Index}
                            />
                            <AuthenticatedRoute
                              path={Routes.ScheduledNotifications.Index}
                              component={AsyncScheduledNotifications}
                            />
                            <AuthenticatedRoute
                              path={Routes.DesktopUsers.Index}
                              component={AsyncDesktopUsers}
                            />
                            <AuthenticatedRoute path={Routes.LPI.Index} component={AsyncLPI} />
                            <AuthenticatedRoute
                              path={Routes.NightBell.Index}
                              component={AsyncNightBell}
                            />
                            <AuthenticatedRoute
                              path={Routes.IPSpeaker.Index}
                              component={AsyncIPSpeakers}
                            />
                            <AuthenticatedRoute
                              path={Routes.IPSpeakerV2.Index}
                              component={AsyncIPSpeakersV2}
                              componentProps={{ isDesktopNotifier: false }}
                            />
                            <AuthenticatedRoute
                              path={Routes.DesktopNotifier.Index}
                              component={AsyncDesktopNotifiers}
                            />
                            <AuthenticatedRoute
                              path={Routes.DesktopNotifierV2.Index}
                              component={AsyncIPSpeakersV2}
                              componentProps={{ isDesktopNotifier: true }}
                            />
                            <AuthenticatedRoute
                              path={Routes.QuickURL.Index}
                              component={AsyncQuickURL}
                            />
                            <AuthenticatedRoute path={Routes.SMS} component={AsyncSMS} />
                            <AuthenticatedRoute path={Routes.DND} component={AsyncDoNotDisturb} />
                            <AuthenticatedRoute
                              path={Routes.InformaCastApps}
                              component={AsyncInformaCastApps}
                            />
                            <AuthenticatedRoute
                              path={Routes.KontaktioWearablePanicButton.Index}
                              component={AsyncKontaktioWearablePanicButton}
                            />
                            <AuthenticatedRoute
                              path={Routes.Applications.Index}
                              component={AsyncApplications}
                            />
                            <AuthenticatedRoute
                              path={Routes.PublicApplications.Index}
                              component={AsyncPublicApplications}
                            />
                            <AuthenticatedRoute path={Routes.Settings} component={AsyncSettings} />
                            <AuthenticatedRoute path={Routes.Calling} component={AsyncCalling} />
                            <AuthenticatedRoute path={Routes.Email} component={AsyncEmail} />
                            <AuthenticatedRoute
                              path={Routes.MSTeamsConfig}
                              component={AsyncMSTeamsConfig}
                            />
                            {webexCallingEnabled && (
                              <AuthenticatedRoute
                                path={Routes.WebexCallingConfig}
                                component={AsyncWebexCallingConfig}
                              />
                            )}
                            <AuthenticatedRoute
                              path={Routes.DialToIntercom}
                              component={AsyncDialToIntercom}
                            />
                            <AuthenticatedRoute
                              path={Routes.VoiceMenus.Index}
                              component={AsyncVoiceMenus}
                            />
                            <AuthenticatedRoute
                              path={Routes.BroadcastParameters.Index}
                              component={AsyncBroadcastParameters}
                            />
                            <AuthenticatedRoute
                              path={Routes.NetworkParameters.Index}
                              component={AsyncNetworkConfig}
                            />
                            <AuthenticatedRoute
                              path={Routes.SlpParameters.Index}
                              component={AsyncSlpParametersConfig}
                            />
                            <AuthenticatedRoute
                              path={Routes.PagingGateway.Index}
                              component={AsyncPagingGateway}
                            />
                            <AuthenticatedRoute
                              path={Routes.SpeakerSettings.Index}
                              component={AsyncSpeakerParameters}
                            />
                            <AuthenticatedRoute
                              path={Routes.TrackingEvents.Index}
                              component={AsyncTrackingEvents}
                            />
                            <AuthenticatedRoute
                              path={Routes.TrackingEventNotifications.Index}
                              component={AsyncTrackingEventNotifications}
                            />
                            <AuthenticatedRoute
                              path={Routes.Reports.Index}
                              component={AsyncReports}
                            />
                            <AuthenticatedRoute
                              path={Routes.CucmClusters.Index}
                              component={AsyncCucmClusters}
                            />
                            <AuthenticatedRoute path={Routes.Zoom.Index} component={AsyncZoom} />
                            <AuthenticatedRoute
                              path={Routes.SystemHealth.Index}
                              component={AsyncSystemHealth}
                            />
                            <Route path="/reload" component={RedirectRouteContainer} />
                          </SafeSwitch>
                        </ErrorBoundary>
                      )}
                    />
                  </SinglewireFormsContextProvider>
                )}
              </div>
            </section>
          </main>
          {initialized && isLoggedIn && <FooterContainer />}
        </>
      </BrandingContext.Provider>
    );
  }

  closeLeftSidebar = () => {
    if (this.props.leftSidebarOpened) {
      this.props.closeSidebar(SidebarType.Left);
    }
  };
}
