import map from 'lodash/map';
import { WrappedFieldProps } from 'redux-form';
import { shared_t } from '../../SharedLocale';

interface ValidationError {
  [errorName: string]: any;
}

const NO_ERRORS = {};

export const getErrors = ({ meta }: Partial<WrappedFieldProps>): ValidationError => {
  if (meta?.error && meta?.submitFailed) {
    return meta?.error;
  } else {
    return NO_ERRORS;
  }
};

export const parseErrors = (errors: ValidationError): string[] => {
  return map(
    errors,
    (error: any, errorName: string) =>
      (error && error.message) || shared_t(['message', 'validator', 'invalid'])
  );
};
