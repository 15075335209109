import { push } from 'connected-react-router';
import { createAction } from 'typesafe-actions';
import { commonSave } from '../../../core/forms/FormActions';
import { jsonToFormData } from '../../../core/forms/FormUtils';
import { growl } from '../../../core/layout/LayoutActions';
import { reloadCurrentPage } from '../../../core/navigation/NavigationActions';
import Routes from '../../../core/route/Routes';
import {
  MobileApiAreaOfInterest,
  MobileApiNotification,
  MobileApiNotificationActivity,
  MobileApiNotificationRecipient,
} from '../../../mobile-api-types';
import { FormState } from '../../layout/CancelOrExpireFormContainer';
import { IOverlayContext } from './../../shared/Overlay';
import { notifications_t } from './NotificationsLocale';
import { AOIRecipientCounts, AllChannelsReportData } from './NotificationsReducer';
import { formatMessagePayload } from './NotificationsUtils';
import { NotificationFormState } from './send/SendNotificationFormContainer';

export const formatFormState = (formState: NotificationFormState) => {
  return {
    ...formState,

    // This is where the tiles on the send page can remove recipients at send time
    // Need to send empty string to remove the values at send time
    distributionListIds: formState.distributionListIdsInclude ? formState.distributionListIds : '',
    deviceGroupIds: formState.deviceGroupIdsInclude ? formState.deviceGroupIds : '',
    /**
     * When sending a followup notification to tracking event user(s), there is a tracking event
     * id in the route params, and we SHOULD NOT strip out user ids in the users section because we
     * are FORCED to send to these users on the followup page.
     */
    userIds: formState.userIdsInclude || formState.isTrackingEvent ? formState.userIds : '',
    collaborationGroupIds: formState.collaborationGroupIdsInclude
      ? formState.collaborationGroupIds
      : '',
    areaOfInterestIds: formState.areaOfInterestIdsInclude ? formState.areaOfInterestIds : '',

    // This is where the tiles on the send page can remove content at send time
    imageFiles: undefined,
    image: formState.imageInclude
      ? formState.image.cropped || formState.image.uploaded || formState.image.original
      : '',
    audio: formState.audioInclude ? formState.audio : '',
    alertTone: formState.audioInclude ? formState.alertTone : 'default',
    confirmationRequestId: formState.confirmationRequestIdInclude
      ? formState.confirmationRequestId
      : '',
    incidentPlanId: formState.incidentPlanIdInclude ? formState.incidentPlanId : '',
  };
};

interface SendNotificationOptions {
  successRoute?: string;
  params?: object;
}

/**
 * Handled in commonSaveEpic
 * Wraps commonSave, initiates a POST request to send a notification.
 */
export const sendNotification = (
  formId: string,
  formState: NotificationFormState,
  overlayContext: IOverlayContext | null,
  opts?: SendNotificationOptions
) => {
  const formatted = formatMessagePayload(formatFormState(formState) as any);

  return commonSave({
    subType: '[Notification] Send',
    formId,
    formData: formState,
    request: [
      'notifications',
      [],
      'POST',
      {
        data: jsonToFormData(formatted),
        params: { ...(opts?.params || {}), validateTts: 'length' },
      },
    ],
    isUpdate: false,
    options: {
      successActions: response => {
        const notification = response.data;

        return (
          overlayContext?.onCreate?.(notification) || [
            growl(notifications_t(['createSuccess'])),
            push(opts?.successRoute || Routes.Notifications.Details(notification.id)),
          ]
        );
      },
    },
  });
};

/**
 * Handled in commonSaveEpic
 * Wraps commonSave, initiates a POST request to cancel or expire a notification.
 */
export const cancelOrExpireNotification = (
  formId: string,
  formData: FormState,
  notification: MobileApiNotification,
  type: 'abort' | 'expire'
) => {
  const data = {
    notificationId: notification.id,
    type,
    details: { comment: formData.comment || null },
  };

  return commonSave({
    subType: '[Notifications] Cancel Notification',
    formId,
    formData,
    request: ['notificationActivities', [notification.id], 'POST', { data }],
    isUpdate: false,
    options: {
      successActions: () => [
        growl(
          notifications_t(['cancelOrUpdateSuccess', 'message'], {
            type:
              type === 'abort'
                ? notifications_t(['cancelOrUpdateSuccess', 'canceled'])
                : notifications_t(['cancelOrUpdateSuccess', 'expired']),
          })
        ),
        reloadCurrentPage(),
      ],
    },
  });
};

/**
 * Handled in: Epic
 * Initiates a GET request to /notifications/:notificationId.  Used for polling for latest
 * changes to the notification's state
 */
export const refreshNotificationState = createAction(
  '[Notifications] Refresh Notification State',
  (notificationId: string) => ({ notificationId })
)();

/**
 * Handled in: Epic
 * Stops refreshing the notification state by triggering the takeUntil operator's stop condition
 */
export const stopRefreshNotificationState = createAction(
  '[Notifications] Stop Refresh Notification State'
)();

/**
 * Handled in: Epic
 * Initiates a GET request to /notifications/:notificationId.  Used for polling for latest
 * changes to the notification's state in the preview
 */
export const refreshNotificationPreviewState = createAction(
  '[Notifications] Refresh Notification Preview State',
  (notificationId: string) => ({ notificationId })
)();

/**
 * Handled in: Epic
 * Stops refreshing the preview notification state by triggering the takeUntil operator's stop condition
 */
export const stopRefreshNotificationPreviewState = createAction(
  '[Notifications] Stop Refresh Notification Preview State'
)();

/**
 * Handled in: Epic
 * Initiates a GET request interval to each mobile channel extension (via reporting microservice)
 * to get the latest "sent" and "failed" values.
 */
export const fetchSendStatusReports = createAction(
  '[Notifications] Fetch Send Status',
  (notificationId: string) => ({ notificationId })
)();

/**
 * Handled in: Epic
 * Stops refreshing the notification's send status by triggering the takeUntil operator's stop condition
 */
export const stopFetchSendStatusReports = createAction(
  '[Notifications] Stop Fetch Send Status Reports'
)();

/**
 * Handled in: Reducer
 * Updates the channel send data object in the store
 */
export const setReportingChannelSendData = createAction(
  '[Notifications] Set Channel Send Data',
  (data: AllChannelsReportData) => ({ data })
)();

/**
 * Handled in: Reducer
 * Updates the boolean flag in the store that determines whether or not to
 * show send status table and chart
 */
export const setReportingDataFetched = createAction(
  '[Notifications] Set Should Show Sent Chart',
  (reportingDataFetched: boolean | { error: string }) => ({ reportingDataFetched })
)();

/**
 * Handled in: Epic
 * Make request Set up initial Google Map state.
 */
export const initializeMap = createAction(
  '[Notifications] Initialize AOI Recipients Map',
  (aois: MobileApiAreaOfInterest[], notificationId: string) => ({ aois, notificationId })
)();

/**
 * Handled in: Epic
 * Fetch the number of recipients in each AOI.
 */
export const fetchAOIRecipientCounts = createAction(
  '[Notifications] Fetch AOI Recipient Counts',
  (aois: MobileApiAreaOfInterest[], notificationId: string) => ({ aois, notificationId })
)();

/**
 * Handled in: Reducer
 */
export const setAOIRecipientCounts = createAction(
  '[Notifications] Set AOI Recipient Counts',
  (counts: AOIRecipientCounts) => ({ counts })
)();

/**
 * Handled in: Epic
 * Position the map so all the aois are visible.
 */
export const centerMapAroundAOIs = createAction(
  '[Notifications] Center Map Around Recipient AOIs',
  (aois: MobileApiAreaOfInterest[]) => ({ aois })
)();

/**
 * Handled in: Reducer
 */
export const setAOIMapBounds = createAction(
  '[Notifications] Set AOI Map Bounds',
  (bounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral) => ({ bounds })
)();

/**
 * Handled in: Reducer
 */
export const clearRecipientAOIs = createAction('[Notifications] Clear Recipient AOIS')();

/*
 * Handled in: Epic.
 * Fetch all notification activities, use only for creating a csv to download.
 */
export const fetchAllActivities = createAction(
  '[Notifications] Fetch All Activities',
  (notification: MobileApiNotification, start?: string) => ({ notification, start })
)();

/*
 * Handled in: Reducer.
 */
export const setActivitiesLoadingStatus = createAction(
  '[Notifications] Set Activities Loading Status',
  (status: boolean) => ({ status })
)();

/*
 * Handled in: Reducer.
 * Merge fetched activities chunk into the state holding all notification activities
 */
export const addActivitiesChunk = createAction(
  '[Notifications] Add Activities Chunk',
  (chunk: MobileApiNotificationActivity[]) => ({ chunk })
)();
/*
 * Handled in: Reducer.
 * Clears out notification's activities
 */
export const clearActivities = createAction('[Notifications] Clear Activities')();

/*
 * Handled in: Reducer.
 */
export const setActivitiesQuery = createAction(
  '[Notifications] Set Activities Query',
  (query: string) => ({ query })
)();

/*
 * Handled in: Reducer.
 */
export const setIsNotificationStale = createAction(
  '[Notifications] Set Is Notification Stale',
  (isNotificationStale: boolean) => ({ isNotificationStale })
)();

/**
 * Handled in: Epic
 * Do a draft send of a notification for the summary page
 */
export const previewNotification = createAction(
  '[Notifications] Preview Notification',
  (overlayContext: IOverlayContext | null, opts?: SendNotificationOptions) => ({
    overlayContext,
    opts,
  })
)();

/**
 * Handled in: Epic
 * Do a draft send of a notification for the summary page
 */
export const setPreviewNotification = createAction(
  '[Notifications] Set Preview Notification',
  (draft: MobileApiNotification) => ({ draft })
)();

/**
 * Handled in: Reducer
 * Exit summary page back to send page
 */
export const endPreviewNotification = createAction('[Notifications] End Preview Notification')();

/*
 * Handled in: Epic.
 * Fetch all notification recipients, use only for creating a csv to download responses.
 */
export const fetchAllRecipients = createAction(
  '[Notifications] Fetch All Recipients',
  (notification: MobileApiNotification, start?: string) => ({ notification, start })
)();

/*
 * Handled in: Reducer.
 */
export const setRecipientsLoadingStatus = createAction(
  '[Notifications] Set Recipients Loading Status',
  (status: boolean) => ({ status })
)();

/*
 * Handled in: Reducer.
 * Merge fetched recipients chunk into the state holding all notification recipients
 */
export const addRecipients = createAction(
  '[Notifications] Add Recipients Chunk',
  (recipients: MobileApiNotificationRecipient[]) => ({ recipients })
)();

/*
 * Handled in: Reducer.
 * Clears out notification's recipients
 */
export const clearRecipients = createAction('[Notifications] Clear Recipients')();
