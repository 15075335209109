import classNames from 'classnames';
import { createRef, PureComponent, RefObject } from 'react';
import { Badge, Box, COLORS, Icon, IconButton, Tooltip, Typography } from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../CommonLocale';
import { RightSidebarType } from '../../core/layout/LayoutActions';
import Routes from '../../core/route/Routes';
import { getPagerData } from '../../core/search-pager/SearchPagerReducer';
import { MobileApiMessageTemplate } from '../../mobile-api-types';
import { Link } from '../shared/components/Link';
import { SearchPagerContainer } from '../shared/components/TableContainer';
import { FlexPageControls } from '../shared/SearchPager/FlexPageControls';
import { SearchPaginationControlsProps } from '../shared/SearchPager/SearchPaginationControls';
import { shared_t } from '../shared/SharedLocale';
import { layout_t } from './LayoutLocale';
import { QuickSendProps } from './QuickSendContainer';
import { GLOBAL_FACILITY_ID } from '../../constants';

export const PAGER_ID = 'list-message-templates-quick-send';
export const GLOBAL_PAGER_ID = 'list-global-message-templates-quick-send';

interface QuickSendListItemProps {
  messageTemplate: MobileApiMessageTemplate;
  toggleRightSidebarType: () => void;
}

const MaxWidthLink = styled(Link)`
  max-width: 19rem;
  margin-left: -1rem;
  margin-right: -1rem;
`;

const Header = styled.div`
  height: 2.5rem;
  padding: 0 0.5rem;
`;

const QuickSendClose = styled(IconButton)`
  margin-top: -0.5rem !important;
  margin-right: -0.5rem !important;
`;

const Subtitle = styled.div`
  color: ${COLORS.GRAY_80};
  font-size: 0.875rem;
  height: 2.5rem;
  background-color: ${COLORS.GRAY_5};
  padding: 0.5rem;
`;

const QuickSendListIcon = styled.div`
  color: ${COLORS.WHITE} !important;
  background: #707070 !important;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.25rem;
`;

const CenterIcon = styled(Icon.MessageTemplate)`
  margin: 0.5rem;
`;

export const QuickSendListItem = ({
  messageTemplate,
  toggleRightSidebarType,
}: QuickSendListItemProps) => {
  return (
    <MaxWidthLink
      to={`${Routes.Notifications.Send}?messageTemplateId=${messageTemplate.id}`}
      className="lv-item hoverable"
      onClick={toggleRightSidebarType}>
      <Box>
        <Box>
          <QuickSendListIcon>
            <CenterIcon color="inherit" size="lg" />
          </QuickSendListIcon>
        </Box>
        <Box flex={false} ml="sm">
          <Typography variant="body">
            {messageTemplate.name!.length > 20
              ? `${messageTemplate.name?.substring(0, 20)}...`
              : messageTemplate.name}
          </Typography>

          <Typography variant="helptext">
            {messageTemplate.subject!.length > 24
              ? `${messageTemplate.subject?.substring(0, 24)}...`
              : messageTemplate.subject}
          </Typography>
          <Box mt="xs">
            {messageTemplate.image > 0 && (
              <Box mr="xs">
                <Tooltip title={common_t(['label', 'image'])}>
                  <Badge variant="outline">
                    <Icon.Image size="sm" />
                  </Badge>
                </Tooltip>
              </Box>
            )}
            {messageTemplate.audio > 0 && (
              <Box mr="xs">
                <Tooltip title={common_t(['label', 'audio'])}>
                  <Badge variant="outline">
                    <Icon.Microphone size="sm" />
                  </Badge>
                </Tooltip>
              </Box>
            )}
            {!!messageTemplate.confirmationRequestId && (
              <Box mr="xs">
                <Tooltip title={common_t(['resource', 'confirmationRequest'])}>
                  <Badge variant="outline">
                    <Icon.ConfirmationRequest size="sm" />
                  </Badge>
                </Tooltip>
              </Box>
            )}
            {!!messageTemplate.incidentPlanId && (
              <Box mr="xs">
                <Tooltip title={common_t(['resource', 'incidentPlan'])}>
                  <Badge variant="outline">
                    <Icon.IncidentPlan size="sm" />
                  </Badge>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </MaxWidthLink>
  );
};

export class CustomControls extends PureComponent<SearchPaginationControlsProps> {
  getComputedHeight = (elem: HTMLElement) =>
    parseFloat((window as any).getComputedStyle(elem, null).getPropertyValue('height'));

  getVanillaHeight = (elem: HTMLElement) =>
    this.getComputedHeight(elem) -
    parseFloat((window as any).getComputedStyle(elem, null).getPropertyValue('padding-top')) -
    parseFloat((window as any).getComputedStyle(elem, null).getPropertyValue('padding-bottom'));

  componentDidMount() {
    // Measure page and put in that many list items.
    const header = (window as any).document.getElementById('quick-send-header');
    const subtitle = (window as any).document.getElementById('quick-send-subtitle');
    const listItemNode = (window as any).document.getElementsByClassName('lv-item')[0];
    const controlsNode = (window as any).document.getElementById('quick-send-nav-row');
    const containerNode = (window as any).document.getElementById('quick-send');

    const searchBarHeight = 100;
    const headerHeight = this.getComputedHeight(header);
    const subtitleHeight = this.getComputedHeight(subtitle);
    const listItemHeight = this.getComputedHeight(listItemNode);
    const controlsHeight = this.getComputedHeight(controlsNode);
    const containerHeight = this.getVanillaHeight(containerNode);

    const itemLimit = Math.floor(
      (containerHeight - (controlsHeight + searchBarHeight + headerHeight + subtitleHeight)) /
        listItemHeight
    );

    this.props.onLimitChanged(Math.max(itemLimit, 1), { keepGlobalLimit: true });
  }

  render() {
    return (
      <Box id="quick-send-nav-row" flex={false}>
        <FlexPageControls {...this.props} />
      </Box>
    );
  }
}

export interface SearchBarComponentProps {
  onSearchQueryChanged: (q: string) => void;
  searchInputRef: RefObject<HTMLInputElement>;
  defaultValue?: string;
  maxSearchLength?: number;
}

export class QuickSend extends PureComponent<QuickSendProps> {
  private readonly searchInputRef: RefObject<HTMLInputElement> = createRef();

  componentDidUpdate(prevProps: Readonly<QuickSendProps>) {
    if (
      this.props.rightSidebarOpened &&
      this.props.rightSidebarType === RightSidebarType.SEND &&
      prevProps.rightSidebarType !== RightSidebarType.SEND
    ) {
      this.props.searchPagerRefresh(
        this.props.facilitiesEnabled && this.props.actingFacilityId === GLOBAL_FACILITY_ID
          ? GLOBAL_PAGER_ID
          : PAGER_ID
      );
      if (this.searchInputRef.current) {
        this.searchInputRef.current.focus();
      }
    } else if (
      this.props.rightSidebarOpened &&
      this.props.facilitiesEnabled &&
      this.props.actingFacilityId !== prevProps.actingFacilityId
    ) {
      this.props.searchPagerRefresh(
        this.props.facilitiesEnabled && this.props.actingFacilityId === GLOBAL_FACILITY_ID
          ? GLOBAL_PAGER_ID
          : PAGER_ID
      );
    }
  }

  render() {
    const {
      rightSidebarOpened,
      rightSidebarType,
      toggleRightSidebarType,
      globalPager,
      pager,
      facilitiesEnabled,
      actingFacilityId,
    } = this.props;

    const useGlobalPager = facilitiesEnabled && actingFacilityId === GLOBAL_FACILITY_ID;

    return (
      <>
        {rightSidebarType === RightSidebarType.SEND && (
          <aside
            id="quick-send"
            className={classNames('sidebar c-overflow', { toggled: rightSidebarOpened })}>
            <div className="listview" id="quickSendItemList">
              <Header id="quick-send-header">
                <Box align="between">
                  <Typography variant="h3">{common_t(['label', 'send'])}</Typography>
                  <QuickSendClose
                    id="close-quick-send"
                    label={layout_t(['quickSend', 'closeSend'])}
                    color="gray"
                    icon={<Icon.Cancel />}
                    onClick={() => toggleRightSidebarType(RightSidebarType.NONE)}
                  />
                </Box>
              </Header>
              <Subtitle id="quick-send-subtitle">
                {common_t(['resource', 'messageTemplates'])}
              </Subtitle>
              <Box m="md">
                <SearchPagerContainer
                  pagerId={useGlobalPager ? GLOBAL_PAGER_ID : PAGER_ID}
                  apiRequest={[
                    'messageTemplates',
                    [],
                    'GET',
                    {
                      params: {
                        q: 'follow-up:false',
                        'include-notification-profile': true,
                        ...(useGlobalPager ? { facilityId: actingFacilityId } : {}),
                      },
                    },
                  ]}
                  customControls={(props: SearchPaginationControlsProps) => (
                    <CustomControls {...props} />
                  )}
                  options={{
                    noResultsText: shared_t(['pager', 'noResults'], {
                      resource: common_t(['resource', 'messageTemplatesLower']),
                    }),
                    noSearchResultsText: shared_t(['pager', 'noSearchResults'], {
                      resource: common_t(['resource', 'messageTemplatesLower']),
                    }),
                    isLazy: true,
                    searchGridItemSize: 12,
                  }}>
                  {getPagerData<MobileApiMessageTemplate>(useGlobalPager ? globalPager : pager).map(
                    template => (
                      <QuickSendListItem
                        key={template.id}
                        messageTemplate={template}
                        toggleRightSidebarType={() => toggleRightSidebarType(RightSidebarType.NONE)}
                      />
                    )
                  )}
                </SearchPagerContainer>
              </Box>
            </div>
          </aside>
        )}
      </>
    );
  }
}
