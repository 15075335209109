import {
  MobileApiBranding,
  MobileApiExtension,
  MobileApiIDNExtensionConfig,
  MobileApiLoadDefinition,
  MobileApiMessageTemplate,
  MobileApiNotification,
  MobileApiUser,
} from '../../mobile-api-types';
import { BRANDINGS_ID } from './../../constants';
import { appendTimestampToUrl, stringifyReq } from './api-resources';

export const getMessageTemplateImageUrl = (
  template?: MobileApiMessageTemplate,
  cacheKey?: string
) =>
  template?.image
    ? stringifyReq([
        'messageTemplateImage',
        [template.id],
        'GET',
        { params: { cacheBust: cacheKey } },
      ])
    : undefined;

export const getNotificationImageUrl = (
  notification: MobileApiNotification | undefined,
  download?: boolean
) =>
  notification?.image
    ? stringifyReq([
        'notificationImage',
        [notification.id],
        'GET',
        { params: { download: !!download } },
      ])
    : undefined;

export const getUserNotificationImageUrl = (
  notification: MobileApiNotification | undefined,
  user: MobileApiUser | undefined
) =>
  notification?.image && user
    ? stringifyReq(['userNotificationImage', [user.id, notification.id], 'GET'])
    : undefined;

export const getIDNImageUrl = (idnExtension?: MobileApiExtension<MobileApiIDNExtensionConfig>) =>
  idnExtension?.config && !!idnExtension.config.logoId
    ? stringifyReq(['extensionAttachments', [idnExtension.id, idnExtension.config.logoId], 'GET'])
    : undefined;

export const getImageFileUrl = (imageUrl?: string) => imageUrl || undefined;

export const getUserNotificationAudioUrl = (
  notification: MobileApiNotification | undefined,
  user: MobileApiUser | undefined
) =>
  notification?.audio && user
    ? stringifyReq(['userNotificationAudio', [user.id, notification.id], 'GET'])
    : undefined;

export const getMessageTemplateAudioUrl = (
  messageTemplate: MobileApiMessageTemplate | undefined
) =>
  messageTemplate?.audio
    ? stringifyReq(['messageTemplateAudio', [messageTemplate.id], 'GET'])
    : undefined;

export const getNotificationAudioUrl = (
  notification: MobileApiNotification | undefined,
  download?: boolean
) =>
  notification?.audio
    ? stringifyReq([
        'notificationAudio',
        [notification.id],
        'GET',
        { params: { download: !!download } },
      ])
    : undefined;

export const getAudioFileUrl = (audioUrl: string | undefined) => audioUrl || undefined;

export const getLoadRequestTemplateUrl = (loadDefinition?: MobileApiLoadDefinition) =>
  loadDefinition ? stringifyReq(['loadDefinitionTemplate', [loadDefinition.id], 'GET']) : undefined;

export const getSelfRegistrationLogo = (branding?: MobileApiBranding) => {
  if (branding?.logo) {
    const logoUrl = stringifyReq(['brandingsLogo', [BRANDINGS_ID], 'GET']);

    // Make logo url unique so that when the branding logo is updated,
    // it gets refetched even though the logo endpoint is the same
    return appendTimestampToUrl(logoUrl);
  }
  return undefined;
};

export const getIncidentResourceFileUrl = (
  incidentId: string,
  resourceId: string,
  params: { format?: 'json' | 'redirect' } = {}
) => stringifyReq(['incidentResourceFile', [incidentId, resourceId], 'GET', { params }]);

export const getIncidentPlanResourceFileUrl = (
  incidentPlanId: string,
  resourceId: string,
  params: { format?: 'json' | 'redirect' } = {}
) => stringifyReq(['incidentPlanResourceFile', [incidentPlanId, resourceId], 'GET', { params }]);

export const getExtension = (contentType: string | null) => {
  switch (contentType) {
    case 'image/jpeg':
      return 'jpg';
    case 'image/png':
      return 'png';
    case 'image/bmp':
      return 'bmp';
    case 'application/pdf':
      return 'pdf';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'application/vnd.ms-excel':
      return 'xls';
    case 'application/msword':
      return 'doc';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/vnd.ms-powerpoint':
      return 'ppt';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'pptx';
    case 'text/plain':
      return 'txt';
    default:
      return '';
  }
};
